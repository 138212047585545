import { FC } from "react";
import { useDispatch } from "react-redux";

import SelectLanguage from "components/SelectLanguage";

import { userSaveLocalSettingsRequest } from "store/user/reducers";

const SidebarBottom: FC = () => {
  const dispatch = useDispatch();

  const handleChangeLanguage = (lng: string) => {
    dispatch(userSaveLocalSettingsRequest({ data: { language: lng } }));
  };

  return (
    <div className="sidebar-bottom">
      <SelectLanguage
        clasName="sidebar-bottom__lang"
        placement="top"
        renderInWrapper={true}
        onChangeLng={handleChangeLanguage}
      />
    </div>
  );
};

export default SidebarBottom;
