import { put, takeLatest, all, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash/isFunction";

import request from "API";

import {
  getUserInfoRequest,
  getUserInfoFailure,
  getUserInfoSuccess,
  userSaveLocalSettingsSuccess,
  getUserInfoSetData,
  userSaveLocalSettingsFailure,
  userSaveLocalSettingsRequest

} from "store/user/reducers";
import { checkIsAuth } from "store/auth/reducers";
import {
  TUserInfoData, TUserSaveLocalSettingsData
} from "store/user/types";

import { setAmplitudeUserId, setAmplitudeUserProperties } from "utils/amplitudeAnalytic";
import { setGoogleUserId } from "utils/googleAnalytic";

import { IPayloadAction } from "../rootInterface";

function* getUserInfo(action: IPayloadAction<{ callOnSuccess?: () => void }>) {
  try {
    const response: AxiosResponse = yield call(request.get, "/user/me/");
    yield setAmplitudeUserId(response.data.id);
    yield setAmplitudeUserProperties<TUserInfoData>(response.data);

    setGoogleUserId(response.data.id);

    yield put(checkIsAuth(true));
    yield put(getUserInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (e) {
    yield put(getUserInfoFailure(e));
  }
}


function* userSaveLocalSettings(action: IPayloadAction<{
  data: TUserSaveLocalSettingsData, callOnSuccess?: () => void }>
) {
  const { data, callOnSuccess } = action.payload;
  try {
    const response: AxiosResponse = yield call(
      request.post,
      "/user/save-locale-settings/",
      data
    );
    yield put(userSaveLocalSettingsSuccess(response.data));
    yield put(getUserInfoSetData(response.data));
    if (isFunction(callOnSuccess)) callOnSuccess();
  } catch (e) {
    yield put(userSaveLocalSettingsFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(getUserInfoRequest.type, getUserInfo),
    takeLatest(userSaveLocalSettingsRequest.type, userSaveLocalSettings),
  ]);
}

export default Saga;
