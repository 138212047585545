import { all, fork } from "redux-saga/effects";

import authSaga from 'store/auth/effects';
import modalsSaga from 'store/modals/effects';
import userSaga from 'store/user/effects';
import analyticsSaga from 'store/analytics/effects';
import commonSaga from 'store/common/effects';
import clientsSaga from 'store/clients/effects';
import transactionsSaga from 'store/transactions/effects';
import portfolioSaga from 'store/portfolio/effects';
import amlSaga from 'store/aml/effects';

export default function* rootSaga(): Generator {
  yield all([
    fork(authSaga),
    fork(modalsSaga),
    fork(userSaga),
    fork(analyticsSaga),
    fork(commonSaga),
    fork(clientsSaga),
    fork(transactionsSaga),
    fork(portfolioSaga),
    fork(amlSaga),
  ]);
}
