import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import isFunction from "lodash/isFunction";

import request from "API";

import { IPayloadAction, TPaginationOptions } from "../rootInterface";

import {
  alertsFailure,
  alertsRequest, alertsSuccess,
  amlRiskModelInfoFailure, amlRiskModelInfoRequest,
  amlRiskModelInfoSuccess, amlRisksEntityCategoriesFailure, amlRisksEntityCategoriesRequest,
  amlRisksEntityCategoriesSuccess, amlRisksModelActivateFailure, amlRisksModelActivateRequest,
  amlRisksModelActivateSuccess,
  amlRisksModelCreateFailure,
  amlRisksModelCreateRequest,
  amlRisksModelCreateSuccess,
  amlRisksModelDeleteFailure,
  amlRisksModelDeleteRequest,
  amlRisksModelDeleteSuccess, amlRisksModelEditFailure, amlRisksModelEditRequest,
  amlRisksModelEditSuccess,
  amlRisksModelRemove,
  amlRisksModelRulesFailure,
  amlRisksModelRulesRequest,
  amlRisksModelRulesSuccess,
  amlRisksModelsAppend,
  amlRisksModelSetActivate,
  amlRisksModelsFailure,
  amlRisksModelsRequest,
  amlRisksModelsSuccess,
  amlRisksModelUpdateRulesFailure,
  amlRisksModelUpdateRulesRequest,
  amlRisksModelUpdateRulesSuccess,
  amlRiskSummaryFailure,
  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
} from "./reducers";
import {
  AmlRiskModel,
  AmlRiskModelInfoOptions, AmlRisksEntityCategoriesOptions,
  AmlRisksModelActivateOptions,
  AmlRisksModelCreateOptions,
  AmlRisksModelDeleteOptions,
  AmlRisksModelEditOptions,
  AmlRisksModelRulesOptions,
  AmlRisksModelUpdateRulesOptions, TAlertsFilter, TAlertsOptions,
} from "./types";


function* getAmlRiskSummary() {
  try {
    const response: AxiosResponse = yield call(request.get, "/risks-summary/");
    yield put(amlRiskSummarySuccess(response.data));
  } catch (error) {
    yield put(amlRiskSummaryFailure(error));
  }
}

function* amlRisksModels() {
  try {
    const response: AxiosResponse = yield call(request.get, "/risks/models/");
    yield put(amlRisksModelsSuccess(response.data));
  } catch (error) {
    yield put(amlRisksModelsFailure(error));
  }
}

function* amlRisksModelCreate(action: IPayloadAction<AmlRisksModelCreateOptions>) {
  const { data } = action.payload;
  try {
    const response: AxiosResponse<AmlRiskModel> = yield call(request.post, "/risks/models/create/", data);
    yield put(amlRisksModelCreateSuccess(response.data));
    yield put(amlRisksModelsAppend(response.data));

    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(amlRisksModelCreateFailure(error));
  }
}

function* amlRiskModelInfo(action: IPayloadAction<AmlRiskModelInfoOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, `/risks/models/${id}/info/`);
    yield put(amlRiskModelInfoSuccess(response.data));
  } catch (error) {
    yield put(amlRiskModelInfoFailure(error));
  }
}

function* amlRisksModelActivate(action: IPayloadAction<AmlRisksModelActivateOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.post, `/risks/models/${id}/activate/`);
    yield put(amlRisksModelActivateSuccess(response.data));
    yield put(amlRisksModelSetActivate({ id }));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelActivateFailure(error));
  }
}


function* amlRisksModelDelete(action: IPayloadAction<AmlRisksModelDeleteOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.delete, `/risks/models/${id}/delete/`);
    yield put(amlRisksModelDeleteSuccess(response.data));
    yield put(amlRisksModelRemove({ id }));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelDeleteFailure(error));
  }
}

function* amlRisksEntityCategories(action: IPayloadAction<AmlRisksEntityCategoriesOptions>) {
  try {
    const response: AxiosResponse = yield call(request.get, "/risks/entity-categories/");
    yield put(amlRisksEntityCategoriesSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess(response.data);
  } catch (error) {
    yield put(amlRisksEntityCategoriesFailure(error));
  }
}

function* amlRisksModelEdit(action: IPayloadAction<AmlRisksModelEditOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.patch, `/risks/models/${id}/edit/`, data);
    yield put(amlRisksModelEditSuccess(response.data));
    yield put(amlRiskModelInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelEditFailure(error));
  }
}

function* amlRisksModelRules(action: IPayloadAction<AmlRisksModelRulesOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse = yield call(request.get, `/risks/models/${id}/rules/`);
    yield put(amlRisksModelRulesSuccess(response.data));
  } catch (error) {
    yield put(amlRisksModelRulesFailure(error));
  }
}

function* amlRisksModelUpdateRules(action: IPayloadAction<AmlRisksModelUpdateRulesOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse<AmlRiskModel> =
      yield call(request.post, `/risks/models/${id}/update-rules/`, data);
    yield put(amlRisksModelUpdateRulesSuccess(response.data));
    yield put(amlRiskModelInfoSuccess(response.data));
    if (isFunction(action.payload?.callOnSuccess)) action.payload.callOnSuccess();
  } catch (error) {
    yield put(amlRisksModelUpdateRulesFailure(error));
  }
}


const alertsParams = (
  filter: TAlertsFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.risk_level) params.set("risk_level", String(filter?.risk_level));
  if (filter?.alert_status) params.set("alert_status", String(filter?.alert_status));
  if (filter?.type) params.set("type", String(filter?.type));
  if (filter?.category) params.set("category", String(filter?.category));
  if (filter?.asset) params.set("asset", String(filter?.asset));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));
  if (filter?.search) params.set("search", String(filter?.search));

  if (pagination?.page) params.set("page", String(pagination?.page));
  if (pagination?.size) params.set("size", String(pagination?.size));

  return params;
};

function* alerts(action: IPayloadAction<TAlertsOptions>) {
  const params = alertsParams(action.payload.filter, action.payload.pagination);
  try {
    const response: AxiosResponse = yield call(request.get, "/alerts/", { params });
    yield put(alertsSuccess({ data:  response.data, infiniteScroll: action.payload.infiniteScroll }));
  } catch (error) {
    yield put(alertsFailure(error));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(amlRiskSummaryRequest.type, getAmlRiskSummary),
    takeLatest(amlRisksModelsRequest.type, amlRisksModels),
    takeLatest(amlRiskModelInfoRequest.type, amlRiskModelInfo),
    takeLatest(amlRisksModelCreateRequest.type, amlRisksModelCreate),
    takeLatest(amlRisksModelActivateRequest.type, amlRisksModelActivate),
    takeLatest(amlRisksModelDeleteRequest.type, amlRisksModelDelete),
    takeLatest(amlRisksEntityCategoriesRequest.type, amlRisksEntityCategories),
    takeLatest(amlRisksModelEditRequest.type, amlRisksModelEdit),
    takeLatest(amlRisksModelRulesRequest.type, amlRisksModelRules),
    takeLatest(amlRisksModelUpdateRulesRequest.type, amlRisksModelUpdateRules),
    takeLatest(alertsRequest.type, alerts),
  ]);
}

export default Saga;

