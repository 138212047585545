import { FC } from "react";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { getPageTitle } from "utils/history";

const MobileHeaderTitle: FC = () => {
  const { routePath } = useLayoutAppNameContext();

  const formPageTitle = () => <span>{getPageTitle(routePath || "")}</span>;

  return (
    <div className="header-mobile__title">
      {formPageTitle()}
    </div>
  );
};

export default MobileHeaderTitle;
