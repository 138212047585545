import { createSlice } from "@reduxjs/toolkit";

import { defaultPaginationState, defaultState, PAGINATION_PAGE_LIMIT } from "../constants";
import { IPaginationResponse, IPayloadAction, TPaginationOptions } from "../rootInterface";

import {
  TPortfolioTransactionsData,
  TPortfolioClientsAddWalletOptions,
  TPortfolioPlatform,
  TPortfolioPlatformOptions,
  TPortfolioPlatformsItem,
  TPortfolioStoreState,
  TPortfolioTransactionsOptions,
  TAlert,
  TAlertsOptions,
  TPortfolioWalletInfoOptions,
  TPortfolioWalletInfo
} from "./types";
import { ALERTS_RESULT } from "./constants";


const initialState: TPortfolioStoreState = {
  platforms: defaultState,
  platform: defaultState,
  addWallet: defaultState,
  transactions: {
    ...defaultState,
    data: defaultPaginationState,
    lastElementFromPreviousPage: null,
    firstElementOfNextPage: null,
    initialLoading: true,
  },
  alerts: {
    ...defaultState,
    data: {
      ...defaultPaginationState,
      results: ALERTS_RESULT
    }
  },
  walletInfo: defaultState,
};

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    portfolioPlatformsRequest(state) {
      state.platforms = {
        ...state.platforms,
        fetching: true,
      };
    },
    portfolioPlatformsSuccess(state, action: IPayloadAction<TPortfolioPlatformsItem[]>) {
      state.platforms = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    portfolioPlatformsFailure(state, action) {
      state.platforms = {
        ...state.platforms,
        fetching: false,
        failure: action.payload,
      };
    },

    portfolioPlatformRequest(state, _: IPayloadAction<TPortfolioPlatformOptions>) {
      state.platform = {
        ...state.platform,
        fetching: true,
      };
    },
    portfolioPlatformSuccess(state, action: IPayloadAction<TPortfolioPlatform>) {
      state.platform = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    portfolioPlatformFailure(state, action) {
      state.platform = {
        ...state.platform,
        fetching: false,
        failure: action.payload,
      };
    },

    addWalletRequest(state, _: IPayloadAction<TPortfolioClientsAddWalletOptions>) {
      state.addWallet = {
        ...state.addWallet,
        fetching: true,
      };
    },
    addWalletSuccess(state, action) {
      state.addWallet = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    addWalletFailure(state, action) {
      state.addWallet = {
        ...state.addWallet,
        fetching: false,
        failure: action.payload,
      };
    },


    portfolioTransactionsRequest(state, action: IPayloadAction<TPortfolioTransactionsOptions>) {
      state.transactions = {
        ...state.transactions,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    portfolioTransactionsSuccess(
      state,
      action: IPayloadAction<{
        data: TPortfolioTransactionsData,
        infiniteScroll?: boolean,
        pagination?: TPaginationOptions
      }>
    ) {
      const { data, infiniteScroll, pagination } = action.payload;
      let newResults = data.results;
      const offset = pagination?.offset || 0;

      state.transactions.fetching = false;
      state.transactions.initialLoading = false;

      switch (pagination?.limit) {
      case PAGINATION_PAGE_LIMIT+2:
        newResults = data.results.slice(1, PAGINATION_PAGE_LIMIT+1);
        state.transactions.lastElementFromPreviousPage = data.results[0];
        state.transactions.data.next =
            data.count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.transactions.firstElementOfNextPage = data.results.length === PAGINATION_PAGE_LIMIT+2
          ? data.results[data.results.length-1]
          : null;
        break;
      case PAGINATION_PAGE_LIMIT+1:
        newResults = data.results.slice(0, PAGINATION_PAGE_LIMIT);
        state.transactions.lastElementFromPreviousPage = null;
        state.transactions.data.next =
            data.count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.transactions.firstElementOfNextPage = data.results.length === PAGINATION_PAGE_LIMIT+1
          ? data.results[data.results.length-1]
          : null;
        break;
      default:
        state.transactions.lastElementFromPreviousPage = null;
        state.transactions.firstElementOfNextPage = null;
        state.transactions.data.next = data.next;
        break;
      }

      state.transactions.data.results = infiniteScroll
        ? [...state.transactions.data.results, ...newResults]
        : newResults;
      state.transactions.data.count = data.count;
      state.transactions.infiniteScroll = infiniteScroll;
    },
    portfolioTransactionsFailure(state, action) {
      state.transactions = {
        ...state.transactions,
        initialLoading: false,
        fetching: false,
        failure: action.payload,
      };
    },
    portfolioTransactionsClear(state) {
      state.transactions = {
        ...defaultState,
        data: defaultPaginationState,
        lastElementFromPreviousPage: null,
        firstElementOfNextPage: null,
        initialLoading: true,
      };
    },

    clientsRequest(state, action: IPayloadAction<TAlertsOptions>) {
      state.alerts = {
        ...state.alerts,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    clientsSuccess(state, action: IPayloadAction<{
      data: IPaginationResponse<TAlert>, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.alerts?.data?.results || [];
        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.alerts = {
        fetching: false,
        data: newData,
        failure: null,
      };
    },
    clientsFailure(state, action) {
      state.alerts = {
        ...state.alerts,
        fetching: false,
        failure: action.payload,
      };
    },


    walletInfoRequest(state, _: IPayloadAction<TPortfolioWalletInfoOptions>) {
      state.walletInfo = {
        ...state.walletInfo,
        fetching: true,
      };
    },
    walletInfoSuccess(state, action: IPayloadAction<TPortfolioWalletInfo>) {
      state.walletInfo = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    walletInfoFailure(state, action) {
      state.walletInfo = {
        ...state.walletInfo,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  portfolioPlatformsRequest,
  portfolioPlatformsSuccess,
  portfolioPlatformsFailure,

  portfolioPlatformRequest,
  portfolioPlatformSuccess,
  portfolioPlatformFailure,

  addWalletRequest,
  addWalletSuccess,
  addWalletFailure,

  portfolioTransactionsRequest,
  portfolioTransactionsSuccess,
  portfolioTransactionsFailure,
  portfolioTransactionsClear,

  clientsRequest,
  clientsSuccess,
  clientsFailure,

  walletInfoRequest,
  walletInfoSuccess,
  walletInfoFailure,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;
