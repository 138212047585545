import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import { TUserStoreState } from "./types";

const initialState: TUserStoreState = {
  userInfo: defaultState,
  userSaveLocalSettingsState: defaultState,
};

const userSlace = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserInfoRequest(state) {
      state.userInfo = {
        fetching: true,
        data: null,
        failure: null,
      };
    },
    getUserInfoSuccess(state, action) {
      state.userInfo = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    getUserInfoSetData(state, action) {
      state.userInfo = {
        ...state.userInfo,
        data: {  ...state.userInfo.data, ...action.payload },
      };
    },
    getUserInfoFailure(state, action) {
      state.userInfo = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userSaveLocalSettingsRequest(state, _) {
      state.userSaveLocalSettingsState = {
        ...state.userSaveLocalSettingsState,
        fetching: true,
        failure: null,
      };
    },
    userSaveLocalSettingsSuccess(state, action) {
      state.userSaveLocalSettingsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    userSaveLocalSettingsFailure(state, action) {
      state.userSaveLocalSettingsState = {
        ...state.userSaveLocalSettingsState,
        fetching: false,
        failure: action.payload,
      };
    },


    clearUserState() {
      return initialState;
    }
  },
});

export const {
  getUserInfoFailure,
  getUserInfoSuccess,
  getUserInfoSetData,
  getUserInfoRequest,
  clearUserState,

  userSaveLocalSettingsRequest,
  userSaveLocalSettingsSuccess,
  userSaveLocalSettingsFailure,

} = userSlace.actions;
export default userSlace.reducer;
