import { createBrowserHistory } from "history";
import { TFunction } from "i18next";

import { paths } from "config/paths";
import { ELinks } from "config/types";

import i18n from "../i18n";

export const history = createBrowserHistory();

export const getPageTitle = (pathname: string): TFunction | string=> {
  switch (pathname) {
  case paths.HOME:
    return i18n.t(ELinks.HOME);
  default:
    return "";
  }
};
