import { FC } from "react";

import MobileHeaderLeft from "./components/MobileHeaderLeft";
import MobileHeaderRight from "./components/MobileHeaderRight";
import MobileHeaderTitle from "./components/MobileHeaderTitle";

const MobileHeader: FC = () => (
  <div className="header-mobile__wrapper container mob-p-16">
    <MobileHeaderLeft/>
    <div className="header-mobile">
      <MobileHeaderTitle/>
    </div>
    <MobileHeaderRight/>
  </div>
);

export default MobileHeader;
