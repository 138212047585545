import { FC } from "react";
import { useSelector } from "react-redux";

import PreparedMobileDrawer from "components/PreparedMobileDrawer";
import { useLayoutAppNameContext } from "components/Layout/hooks";

import { getUserInfoSelector } from "store/user/selectors";


export type TMobileTabDrawerProps = {
  visible: boolean
  close: () => void;
}
const MobileTabDrawer: FC<TMobileTabDrawerProps> = ({ visible, close  }) => {
  const { logo } = useLayoutAppNameContext();
  const { data: userInfo } = useSelector(getUserInfoSelector);


  return (
    <PreparedMobileDrawer
      visible={visible}
      close={close}
      hideHeader={true}
      className="mobile-tab-menu__drawer"
    >
      <div className="mobile-tab-menu__drawer__content content">
        <div className="mobile-tab-menu__drawer__header">
          <div className="mobile-tab-menu__drawer__logo">
            {logo}
          </div>
          <div className="mobile-tab-menu__drawer__username">
            {userInfo?.username || userInfo?.email || userInfo?.phone}
          </div>
        </div>
        <div className="mobile-tab-menu__drawer__links"/>
      </div>
    </PreparedMobileDrawer>
  );
};

export default MobileTabDrawer;
