import { FC, Fragment } from "react";
import cn from "classnames";
import { matchPath } from "react-router-dom";
import { Layout as AntLayout } from "antd";
import MediaQuery, { useMediaQuery } from "react-responsive";

import { paths } from "config/paths";
import { maxMobileMediaWidth, minShowSidebarWidth } from "config/constants";

import Header from "components/segments/Header";
import Sidebar from "components/segments/Sidebar";
import NewHeader from "components/segments/NewHeader";
import MobileTabMenu from "components/segments/MobileTabMenu";
import MobileHeader from "components/segments/MobileHeader";

import AnimatedVisibility from "../../../AnimatedVisibility";

import { ILayout } from "../../types";
import { useLayoutAppNameContext } from "../../hooks";

const PrivateContainer: FC<ILayout> = ({ children }) => {
  const { routePath } = useLayoutAppNameContext();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });

  const lightGreyMobile = matchPath(location.pathname, paths.HOME)?.isExact;
  const showSidebar = ![
    paths.CLIENTS_DETAIL,
    paths.CLIENTS_TRANSACTIONS,
    paths.CLIENTS_WALLET,
  ].includes(routePath as paths);

  return <Fragment>
    <MediaQuery minWidth={minShowSidebarWidth}>
      <AnimatedVisibility show={showSidebar}>
        <Sidebar />
      </AnimatedVisibility>
    </MediaQuery>
    <AntLayout className="main-layout">
      {false && <Header/>}
      {!isMaxMobile ? <NewHeader/> : <MobileHeader/>}
      <main
        className={cn("main", {
          "p-t-0": routePath && [paths.TRANSFERS, paths.TRANSACTIONS, paths.CLIENTS_TRANSACTIONS].includes(routePath),
          "light-grey": lightGreyMobile,
          // settings: location.pathname.includes(paths.SETTINGS),
          "dashboard-page": location.pathname === paths.HOME,
        })}
      >
        {children}
      </main>
      {isMaxMobile && <MobileTabMenu/>}
    </AntLayout>

  </Fragment>;
};

export default PrivateContainer;
