import { useRef, useState } from "react";
import cn from "classnames";
import isBoolean from "lodash/isBoolean";
import isFunction from "lodash/isFunction";

import useOnClickOutside from "hooks/useOnClickOutside";

import { TBNDropdownChildren, TBNDropdownProps } from "./types";
import BNDropdownItem from "./components/BNDropdownItem";
import { BNDropdownContext } from "./hooks";

const BNDropdown: TBNDropdownProps = ({
  children,
  overlay,
  active: $active,
  setActive: $setActive,
  className,
  style,
  overlayStyle,
  overlayClass,
  activateOnClickOutside= true,
  onClick,
  outsideClickIgnore= [],
  triggerOnHover = false
}) => {
  const [baseActive, setBaseActive] = useState<boolean>(false);
  const isActive = isBoolean($active) ? $active : baseActive;
  const setActive = $setActive ? $setActive : setBaseActive;

  const clickedRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside({
    onClickOutside: () => setActive(false),
    anchors: [
      wrapperRef,
      clickedRef,
      ...outsideClickIgnore || [],
    ],
    ignore: !activateOnClickOutside,
  });

  const callBack = (): TBNDropdownChildren => ({
    active: isActive, setActive
  });

  const handleMouseEnter = () => {
    clickedRef?.current?.click();
  };

  return (
    <BNDropdownContext.Provider value={{ active: $active, setActive }}>
      <nav className={cn("bn-dropdown-navigation", className)}
        style={style}
        onClick={e => {
          e.stopPropagation();
          if(isFunction(onClick)) onClick({ active: !!$active, setActive });
        }}
      >
        <div
          className={cn("bn-dropdown__appearance", { triggerOnHover })}
          onClick={() => setActive(!isActive)}
          onMouseEnter={() => triggerOnHover && handleMouseEnter()}
          ref={clickedRef}>
          {children(callBack())}
        </div>
        <div
          ref={wrapperRef}
          style={overlayStyle}
          className={cn("bn-dropdown__overlay", overlayClass, {
            active: isActive,
          })}
        >
          {overlay(callBack())}
        </div>
      </nav>
    </BNDropdownContext.Provider>
  );
};

BNDropdown.Item = BNDropdownItem;

export default BNDropdown;
