import findIdFromUrl from "../utils/findIdFromUrl";

export enum paths {
  HOME = "/",
  ALERTS = "/alerts",
  ALERTS_DETAIL = "/alerts/:id",
  SIGN_IN = "/sign-in",
  TRANSFERS = "/transfers",
  CLIENTS_DETAIL = "/clients/:id",
  CLIENTS_TRANSACTIONS = "/clients/:id/transactions",
  CLIENTS_WALLET = "/clients/:id/wallet/:wallet",
  TRANSACTIONS = "/transactions",
  RISK_SETTINGS = "/risks",
  RISK_SETTINGS_DETAILS = "/risks/:id",
}

export const getClientsDetailsPath = (id: string): string =>
  paths.CLIENTS_DETAIL.replace(":id", id);
export const getClientsTransactionsPath = (id: string): string =>
  paths.CLIENTS_TRANSACTIONS.replace(":id", id);
export const getClientsWalletPath = (id: string, wallet: string): string =>
  paths.CLIENTS_WALLET.replace(":id", id).replace(":wallet", wallet);
export const getAlertsDetailPath = (id: string): string =>
  paths.ALERTS_DETAIL.replace(":id", id);
export const getRiskSettingsDetailsPath = (id: string): string =>
  paths.RISK_SETTINGS_DETAILS.replace(":id", id);

export const getClientId = ():string|undefined => findIdFromUrl("/clients/");