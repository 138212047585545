import { AxiosResponse } from "axios";
import { put, takeLatest, all, call, takeEvery, select } from "redux-saga/effects";
import { notification } from "antd";

import request from "../../API";
import { showError, showSuccess, syncNotification } from "../../utils/notifications";
import delay from "../../utils/delay";
import i18n from "../../i18n";

import { IApplicationState, IPaginationResponse, IPayloadAction, TPaginationOptions } from "../rootInterface";
import { PAGINATION_PAGE_LIMIT } from "../constants";

import {
  accountsDeleteTransactionsFailure,
  accountsDeleteTransactionsRequest,
  accountsDeleteTransactionsSuccess,
  accountsUploadTransactionsFailure,
  accountsUploadTransactionsRequest,
  accountsUploadTransactionsSuccess,
  clientAppend,
  clientDeleteFailure,
  clientDeleteRequest,
  clientDeleteSuccess,
  clientFiatTransactionDetailsFailure,
  clientFiatTransactionDetailsRequest,
  clientFiatTransactionDetailsSuccess,
  clientsAccountsFailure,
  clientsAccountsRequest,
  clientsAccountsSuccess,
  clientsConnectWalletFailure,
  clientsConnectWalletRequest,
  clientsConnectWalletSuccess,
  clientsCryptoConnectionsFailure,
  clientsCryptoConnectionsRequest,
  clientsCryptoConnectionsSuccess,
  clientsFailure,
  clientsInfoFailure,
  clientsInfoRequest,
  clientsInfoSuccess,
  clientsRequest,
  clientsSuccess,
  clientsTransactionPreviewFailure,
  clientsTransactionPreviewRequest,
  clientsTransactionPreviewSuccess,
  clientsFiatTransactionsRequest,
  clientsFiatTransactionsFailure,
  clientsFiatTransactionsSuccess,
  creatCreateFailure,
  creatCreateRequest,
  creatCreateSuccess,
} from "./reducers";
import {
  EClientsInfoAnalysisStatus,
  TAccountsUploadTransactionsOptions,
  TClient,
  TClientCreateOptions,
  TClientFiatTransactionDetails,
  TClientFiatTransactionDetailsOptions,
  TClientsAccount,
  TClientsAccountsOptions,
  TClientsConnectWallet,
  TClientsConnectWalletOptions,
  TClientsCryptoConnectionsData,
  TClientsCryptoConnectionsOptions,
  TClientsFilter,
  TClientsInfo,
  TClientsInfoOptions,
  TClientsOptions,
  TClientsTransactionPreviewData,
  TClientsTransactionPreviewOptions,
  TClientsFiatTransactionsData,
  TClientsFiatTransactionsOptions,
  TClientsFiatTransactionsFilter,
  TClintDeleteOptions,
  TAccountsDeleteTransactionsOptions,
} from "./types";


const getClientsParams = (filter: TClientsFilter|undefined, pagination: TPaginationOptions|undefined) => {
  const params = new URLSearchParams();

  params.set("size", pagination?.size
    ? String(pagination?.size) : String(PAGINATION_PAGE_LIMIT));

  if (pagination?.page) params.set("page", String(pagination?.page));
  if (filter?.type) params.set("type", filter?.type);
  if (filter?.crypto_relation_status) params.set("crypto_relation_status", filter?.crypto_relation_status);
  if (filter?.id_search) params.set("id_search", filter?.id_search);

  return params;
};

function* getClients(action: IPayloadAction<TClientsOptions>) {
  const params = getClientsParams(action.payload?.filter, action.payload?.pagination);

  try {
    const response: AxiosResponse<IPaginationResponse<TClient>> = yield call(request.get, "/clients/", { params });
    yield put(clientsSuccess({ data: response.data, infiniteScroll: action?.payload?.infiniteScroll }));
    if (action.payload?.callOnSuccess) action.payload.callOnSuccess();
  } catch (e) {
    yield put(clientsFailure(e));
  }
}
function* creatCreate(action: IPayloadAction<TClientCreateOptions>) {
  try {
    const response: AxiosResponse<TClient> = yield call(request.post, "/clients/new/", action.payload.data);

    yield put(creatCreateSuccess(response.data));
    yield put(clientAppend(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data.id);
  } catch (e) {
    yield put(creatCreateFailure(e));
  }
}

function* clientDelete(action: IPayloadAction<TClintDeleteOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TClient> = yield call(request.delete, `/clients/${id}/delete/`, );

    yield put(clientDeleteSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (e) {
    yield put(clientDeleteFailure(e));
  }
}

function* clientsInfo(action: IPayloadAction<TClientsInfoOptions>) {
  const { id } = action.payload;
  const prevState: TClientsInfo = yield select((state: IApplicationState) => state.clients.clientsInfoState.data);
  try {
    const response: AxiosResponse<TClientsInfo> = yield call(request.get, `/clients/${id}/info/`);
    yield put(clientsInfoSuccess(response.data));

    switch (response.data.analysis_status) {
    case EClientsInfoAnalysisStatus.in_progress:
      yield delay(5000);
      yield put(clientsInfoRequest({
        id,
        fetchTransactionPreviews: action.payload.fetchTransactionPreviews
      }));
      break;
    case EClientsInfoAnalysisStatus.failed:
      if (prevState.analysis_status === EClientsInfoAnalysisStatus.in_progress) {
        notification.destroy();
        showError(i18n.t("clientsDetail.analysisFailed"));
      }
      break;
    case EClientsInfoAnalysisStatus.analyzed:
      if (prevState.analysis_status === EClientsInfoAnalysisStatus.in_progress) {
        notification.destroy();
        showSuccess(i18n.t("clientsDetail.analysisSucceeded"));
      }
      break;
    }

    if (
      (response.data.analysis_status === EClientsInfoAnalysisStatus.analyzed
        || response.data.analysis_status === EClientsInfoAnalysisStatus.failed)
      && prevState.analysis_status === EClientsInfoAnalysisStatus.in_progress
    ) {
      if (action.payload.fetchTransactionPreviews) action.payload.fetchTransactionPreviews();
      yield put(clientsAccountsRequest({ id }));
      yield put(clientsConnectWalletRequest({ id }));
      yield put(clientsCryptoConnectionsRequest({ id }));
    }
  } catch (e) {
    yield put(clientsInfoFailure(e));
  }
}

function* clientsAccounts(action: IPayloadAction<TClientsAccountsOptions>) {
  try {
    const response: AxiosResponse<TClientsAccount[]> =
      yield call(request.get, `/clients/${action.payload.id}/accounts/`);

    yield put(clientsAccountsSuccess(response.data));
  } catch (e) {
    yield put(clientsAccountsFailure(e));
  }
}

function* clientsTransactionPreview(action: IPayloadAction<TClientsTransactionPreviewOptions>) {
  try {
    const response: AxiosResponse<TClientsTransactionPreviewData> =
      yield call(request.get, `/clients/${action.payload.id}/transaction-previews/`, { params: action.payload.params });

    yield put(clientsTransactionPreviewSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess(response.data);
  } catch (e) {
    yield put(clientsTransactionPreviewFailure(e));
  }
}

function* accountsUploadTransactions(action: IPayloadAction<TAccountsUploadTransactionsOptions>) {
  const formData = new FormData();
  formData.append("csv_file", action.payload.data.csv_file);

  try {
    const response: AxiosResponse =
      yield call(request.post, `/accounts/${action.payload.id}/upload-transactions/`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

    syncNotification(i18n.t("clientsDetail.analyzingTransactions"));
    
    yield put(accountsUploadTransactionsSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();

    const clientInfo: TClientsInfo = yield select((state: IApplicationState) => state.clients.clientsInfoState.data);
    yield put(clientsInfoRequest({
      id: clientInfo.id,
      fetchTransactionPreviews: action.payload.fetchTransactionPreviews
    }));
  } catch (e) {
    yield put(accountsUploadTransactionsFailure(e));
  }
}

function* accountsDeleteTransactions(action: IPayloadAction<TAccountsDeleteTransactionsOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TClient> = yield call(request.delete, `/accounts/${id}/delete-transactions/`, );

    yield put(accountsDeleteTransactionsSuccess(response.data));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (e) {
    yield put(accountsDeleteTransactionsFailure(e));
  }
}


function* clientsConnectWallet(action: IPayloadAction<TClientsConnectWalletOptions>) {

  try {
    const response: AxiosResponse<TClientsConnectWallet[]> =
      yield call(request.get, `/clients/${action.payload.id}/connected-wallets/`);

    yield put(clientsConnectWalletSuccess(response.data));
  } catch (e) {
    yield put(clientsConnectWalletFailure(e));
  }
}

function* clientsCryptoConnections(action: IPayloadAction<TClientsCryptoConnectionsOptions>) {

  try {
    const response: AxiosResponse<TClientsCryptoConnectionsData> =
      yield call(request.get, `/clients/${action.payload.id}/crypto-connections/`);

    yield put(clientsCryptoConnectionsSuccess(response.data));
  } catch (e) {
    yield put(clientsCryptoConnectionsFailure(e));
  }
}

const clientsTransactionsParams = (
  filter: TClientsFiatTransactionsFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.client) params.set("client", String(filter?.client));
  if (filter?.risk_level) params.set("risk_level", String(filter?.risk_level));
  if (filter?.type) params.set("type", String(filter?.type));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));
  if (filter?.crypto_related_only) params.set("crypto_related_only", String(filter?.crypto_related_only));
  if (filter?.search) params.set("search", String(filter?.search));

  if (pagination?.limit) params.set("limit", String(pagination?.limit));
  if (pagination?.offset) params.set("offset", String(pagination?.offset));

  return params;
};

function* clientsFiatTransactions(action: IPayloadAction<TClientsFiatTransactionsOptions>) {
  const params = clientsTransactionsParams(action.payload.filter, action.payload.pagination);
  try {
    const response: AxiosResponse<TClientsFiatTransactionsData> =
      yield call(request.get, `/clients/fiat-transactions/`, { params });
    yield put(clientsFiatTransactionsSuccess({
      data: response.data,
      infiniteScroll: action?.payload?.infiniteScroll,
      pagination: action?.payload?.pagination,
    }));
    if (action.payload?.callOnSuccess) {
      if (action?.payload?.infiniteScroll && response.data.next) {
        action.payload.callOnSuccess(response.data.next);
      } else {
        action.payload.callOnSuccess();
      }
    }
  } catch (e) {
    yield put(clientsFiatTransactionsFailure(e));
  }
}

function* clientFiatTransactionDetails(action: IPayloadAction<TClientFiatTransactionDetailsOptions>) {
  const { id } = action.payload;
  
  try {
    const response: AxiosResponse<TClientFiatTransactionDetails> =
      yield call(request.get, `/clients/fiat-transactions/${id}/`);

    yield put(clientFiatTransactionDetailsSuccess(response.data));
  } catch (e) {
    yield put(clientFiatTransactionDetailsFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(clientsRequest.type, getClients),
    takeLatest(creatCreateRequest.type, creatCreate),
    takeLatest(clientDeleteRequest.type, clientDelete),
    takeLatest(clientsInfoRequest.type, clientsInfo),
    takeLatest(clientsAccountsRequest.type, clientsAccounts),
    takeEvery(clientsTransactionPreviewRequest.type, clientsTransactionPreview),
    takeLatest(accountsUploadTransactionsRequest.type, accountsUploadTransactions),
    takeLatest(accountsDeleteTransactionsRequest.type, accountsDeleteTransactions),
    takeLatest(clientsConnectWalletRequest.type, clientsConnectWallet),
    takeLatest(clientsCryptoConnectionsRequest.type, clientsCryptoConnections),
    takeLatest(clientsFiatTransactionsRequest.type, clientsFiatTransactions),
    takeLatest(clientFiatTransactionDetailsRequest.type, clientFiatTransactionDetails),
  ]);
}

export default Saga;
