import { FC } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setMobileAdditionalMenu } from "store/common/reducers";

import { useActiveNavLink } from "../Header/hooks";

import { MOBILE_MENU } from "./container";
import MobileTabAdditionalMenu from "./components/MobileTabAdditionalMenu";

export
const MobileTabMenu: FC = () => {
  const dispatch = useDispatch();
  const isActive = useActiveNavLink();

  return <div className="mobile-tab-menu">
    {MOBILE_MENU.map(link => (
      <div className="mobile-tab-menu__item" key={link.id}>
        <NavLink
          to={link.to}
          exact={true}
          className="mobile-tab-menu__link"
          activeClassName="active"
          isActive={() => isActive(link.to)}
          onClick={() => dispatch(setMobileAdditionalMenu(false))}
        >
          {link.icon && <link.icon />}
        </NavLink>
      </div>)
    )}
    <MobileTabAdditionalMenu/>
  </div>;
};

export default MobileTabMenu;
