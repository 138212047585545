import { paths } from "config/paths";
import { NAVIGATIONS } from "config/constants";

import { TLinks } from "../../../config/types";

export const MOBILE_MENU: TLinks[] = [
  NAVIGATIONS[paths.HOME],
];

export const MOBILE_MENU_ADDITIONAL: TLinks[] = [
];
