import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import { TTransactionsStoreState } from "./types";
import { TRANSACTIONS } from "./constants";

const initialState: TTransactionsStoreState = {
  transactions: {
    ...defaultState,
    data: {
      next: null,
      previous: null,
      count: 100,
      pages	: 1,
      results: TRANSACTIONS
    },
    lastElementFromPreviousPage: null,
    firstElementOfNextPage: null,
    initialLoading: false,
  },
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    clearTransactions() {
      return initialState;
    },
  },
});

export const {
  clearTransactions,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
