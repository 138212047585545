import { all, call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import request from "../../API";

import { IPayloadAction, TPaginationOptions } from "../rootInterface";
import {
  clientsAccountsRequest,
} from "../clients/reducers";
import { TTransactionsFilter } from "../transactions/types";

import {
  addWalletFailure,
  addWalletRequest,
  addWalletSuccess,
  portfolioPlatformFailure,
  portfolioPlatformRequest,
  portfolioPlatformsFailure,
  portfolioPlatformsRequest,
  portfolioPlatformsSuccess,
  portfolioPlatformSuccess,
  portfolioTransactionsFailure,
  portfolioTransactionsRequest,
  portfolioTransactionsSuccess, walletInfoFailure, walletInfoRequest, walletInfoSuccess
} from "./reducers";
import {
  TPortfolioTransactionsData,
  TPortfolioClientsAddWalletOptions,
  TPortfolioPlatform,
  TPortfolioPlatformOptions,
  TPortfolioPlatformsItem, TPortfolioTransactionsOptions, TPortfolioWalletInfoOptions, TPortfolioWalletInfo
} from "./types";


function* portfolioPlatforms() {
  try {
    const response: AxiosResponse<TPortfolioPlatformsItem[]> =
      yield call(request.get, "/portfolio/platforms/");

    yield put(portfolioPlatformsSuccess(response.data));
  } catch (e) {
    yield put(portfolioPlatformsFailure(e));
  }
}


function* portfolioPlatform(action: IPayloadAction<TPortfolioPlatformOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TPortfolioPlatform> =
      yield call(request.get, `/portfolio/platforms/${id}/`);

    yield put(portfolioPlatformSuccess(response.data));
  } catch (e) {
    yield put(portfolioPlatformFailure(e));
  }
}

function* addWallet(action: IPayloadAction<TPortfolioClientsAddWalletOptions>) {
  const { id, data } = action.payload;
  try {
    const response: AxiosResponse =
      yield call(request.post, `/portfolio/clients/${id}/add-wallet/`, data);

    yield put(addWalletSuccess(response.data));
    yield put(clientsAccountsRequest({ id }));
    if (action.payload.callOnSuccess) action.payload.callOnSuccess();
  } catch (e) {
    yield put(addWalletFailure(e));
  }
}


const clientsTransactionsParams = (
  filter: TTransactionsFilter|undefined,
  pagination: TPaginationOptions|undefined
) => {
  const params = new URLSearchParams();

  if (filter?.client) params.set("client", String(filter?.client));
  if (filter?.date_from) params.set("date_from", String(filter?.date_from));
  if (filter?.date_to) params.set("date_to", String(filter?.date_to));

  if (pagination?.limit) params.set("limit", String(pagination?.limit));
  if (pagination?.offset) params.set("offset", String(pagination?.offset));

  return params;
};

function* portfolioTransactions(action: IPayloadAction<TPortfolioTransactionsOptions>) {
  const params = clientsTransactionsParams(action.payload.filter, action.payload.pagination);
  try {
    const response: AxiosResponse<TPortfolioTransactionsData> =
      yield call(request.get, `/portfolio/transactions/`, { params });
    yield put(portfolioTransactionsSuccess({
      data: response.data,
      infiniteScroll: action?.payload?.infiniteScroll,
      pagination: action?.payload?.pagination,
    }));
    if (action.payload?.callOnSuccess) {
      if (action?.payload?.infiniteScroll && response.data.next) {
        action.payload.callOnSuccess(response.data.next);
      } else {
        action.payload.callOnSuccess();
      }
    }
  } catch (e) {
    yield put(portfolioTransactionsFailure(e));
  }
}

function* walletInfo(action: IPayloadAction<TPortfolioWalletInfoOptions>) {
  const { id } = action.payload;
  try {
    const response: AxiosResponse<TPortfolioWalletInfo> =
      yield call(request.get, `/portfolio/wallets/${id}/info/`);

    yield put(walletInfoSuccess(response.data));
  } catch (e) {
    yield put(walletInfoFailure(e));
  }
}


function* Saga(): Generator {
  yield all([
    takeLatest(portfolioPlatformsRequest.type, portfolioPlatforms),
    takeLatest(portfolioPlatformRequest.type, portfolioPlatform),
    takeLatest(addWalletRequest.type, addWallet),
    takeLatest(portfolioTransactionsRequest.type, portfolioTransactions),
    takeLatest(walletInfoRequest.type, walletInfo),
  ]);
}
export default Saga;
