import { EClientsInfoAnalysisStatus, ERiskLevel } from "../clients/types";

import { TAlert } from "./types";

export const ALERTS_RESULT: TAlert[] = [
  {
    id: "asdkapsmpasmda;lsmd",
    risk_level: ERiskLevel.low,
    client_name: "Client name",
    client_ext_id: "12384930",
    type_str: "Type",
    created_at: String(new Date()),
    occurred_at: String(new Date()),
    status: EClientsInfoAnalysisStatus.analyzed,
    assignee_name: "Ivan Ivanov",
    currency: "RUB",
    amount: 999.999,
    child_alerts: 10,
  },
  {
    id: "asdkapsmpasmda;lsmd",
    risk_level: ERiskLevel.severe,
    client_name: "Client name",
    client_ext_id: "12384930",
    type_str: "Type",
    created_at: String(new Date()),
    occurred_at: String(new Date()),
    status: EClientsInfoAnalysisStatus.analyzed,
    assignee_name: "Ivan Ivanov",
    currency: "RUB",
    amount: 123.1232,
    child_alerts: 10,
  },
  {
    id: "asdkapsmpasmda;lsmd",
    risk_level: ERiskLevel.high,
    client_name: "Client name",
    client_ext_id: "12384930",
    type_str: "Type",
    created_at: String(new Date()),
    occurred_at: String(new Date()),
    status: EClientsInfoAnalysisStatus.analyzed,
    assignee_name: "Ivan Ivanov",
    currency: "RUB",
    amount: 100,
    child_alerts: 10,
  },
  {
    id: "asdkapsmpasmda;lsmd",
    risk_level: ERiskLevel.medium,
    client_name: "Client name",
    client_ext_id: "12384930",
    type_str: "Type",
    created_at: String(new Date()),
    occurred_at: String(new Date()),
    status: EClientsInfoAnalysisStatus.analyzed,
    assignee_name: "Ivan Ivanov",
    currency: "RUB",
    amount: 100,
    child_alerts: 10,
  },
  {
    id: "asdkapsmpasmda;lsmd",
    risk_level: ERiskLevel.no_risk,
    client_name: "Client name",
    client_ext_id: "12384930",
    type_str: "Type",
    created_at: String(new Date()),
    occurred_at: String(new Date()),
    status: EClientsInfoAnalysisStatus.analyzed,
    assignee_name: "Ivan Ivanov",
    currency: "RUB",
    amount: 100,
    child_alerts: 10,
  },
];