import { notification } from "antd";

import SyncIcon from "../components/Icons/SyncIcon";
import { colors } from "../config/constants";

export const showError = (message: string, description?: string): void => {
  notification.error({ message, description });
};

export const showSuccess = (message: string): void => {
  notification.success({ message });
};

export const showInfo = (message: string): void => {
  notification.info({ message });
};

export const clearNotification = (): void => {
  notification.destroy();
};

export const syncNotification = (message: string, description: string|undefined = ""): void => {
  notification.open({
    message,
    description,
    icon: <SyncIcon style={{ width: 24, height: 24, top: 12, position: "absolute" }} isSpin={true}/>,
    style: { backgroundColor: colors.gray4, border: `1px solid ${colors.blue1}` },
    duration: 0,
  });
};
