import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import { TAuthStoreState } from "./types";

const initialState: TAuthStoreState = {
  signInState: defaultState,
  isAuthorized: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getSignInRequest(state, action) {
      state.signInState = {
        ...action.payload,
        fetching: true,
        data: null,
        failure: null,
      };
    },
    getSignInSuccess(state, action) {
      state.signInState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
      state.isAuthorized = true;
    },
    getSignInFailure(state, action) {
      state.signInState = {
        fetching: false,
        data: null,
        failure: action.payload,
      };
    },

    logout(state) {
      state.isAuthorized = false;
    },

    checkIsAuth(state, action) {
      state.isAuthorized = action.payload;
    },
    clearAuthState() {
      return initialState;
    }
  },
});

export const {
  getSignInRequest,
  getSignInSuccess,
  getSignInFailure,
  logout,
  clearAuthState,
  checkIsAuth,
} = authSlice.actions;

export default authSlice.reducer;
