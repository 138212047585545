import { FunctionComponent, SVGAttributes } from "react";

import BritishFlag from "components/Icons/Flags/en";
import RussianFlag from "components/Icons/Flags/ru";
import HomeIcon from "components/Icons/HomeIcon";

import { ELanguages } from "../i18n/constants";
import SwapIcon from "../components/Icons/SwapIcon";

import { TLinks } from "./types";
import { paths } from "./paths";

export const lgMediaWidth = 992;

export const mobileMediaWidth375 = 375;
export const mobileMediaWidth400 = 400;
export const mobileMediaWidth460 = 460;
export const mobileMediaWidth = 575.98;
export const maxMobileMediaWidth = 767.98;
export const minTableMediaWidth = 768;
export const moreMdMedia = 868;
export const tableMediaWidth = 924;
export const maxTableMediaWidth = 1024;
export const minLaptopMediaWidth = 1199;
export const minShowSidebarWidth = 1336;
export const contentWidth = 996;


export const colors = {
  main: "#748ADA",
  brown1: "#edd4c4",
  brown2: "#e1b79d",
  brown3: "#d59b76",
  brown4: "#c97e4f",
  brown5: "#b06536",
  brown6: "#894e2a",
  brown7: "#62381e",
  brown8: "#3b2212",
  brown9: "#21130a",

  grayMain: "#39393C",
  gray1: "#ffffff",
  gray2: "#f3f4f9",
  gray3: "#eeeff3",
  gray4: "#e8e9f0",
  gray5: "#d7d8e0",
  gray6: "#b9bbc2",
  gray7: "#8c8d94",
  gray8: "#626368",
  gray9: "#232325",

  grayLight: "#fafafc",
  blue1: "#C5D1FF",
  blue2: "#99ACF2",
  blue3: "#475DAF",
  error2: "#E5424C",
  green1: "#219653",
  green3: "#59bd83",
  red1: "#FFF1F0",
  red2: "#FFCCC7",
  red3: "#FFA39E",
  red4: "#ff7875",
  red5: "#ff4d4f",
  red6: "#e5424C",
  complementary: "#FF6D88",
  complementary2: "#9E83F8",
  darkBlue: "#00d5d7",
  lightBlue: "#00e7e6",
  lettuce: "#CDF964",
  pink: "#D64686",
};


export type LanguageOption = {
  nativeName: string,
  icon: FunctionComponent<SVGAttributes<SVGElement>>
}

export const LANGUAGE_OPTIONS: Record<ELanguages, LanguageOption> = {
  [ELanguages.ruRU]: {
    nativeName: 'Русский',
    icon: RussianFlag
  },
  [ELanguages.enUS]: {
    nativeName: 'English',
    icon: BritishFlag
  },
};


export enum LinkIds {
  ALERTS = "alerts",
  CLIENTS = "clients",
  TRANSFERS = "transfers",
  TRANSACTIONS = "transactions",
  RISK_SETTINGS = "risk-settings",
}
export const NAVIGATIONS = {
  [paths.HOME]: {
    id: LinkIds.CLIENTS,
    to: paths.HOME,
    icon: HomeIcon,
    title: "naming.clients",
    active: true
  },
  [paths.TRANSFERS]: {
    id: LinkIds.TRANSFERS,
    to: paths.TRANSFERS,
    icon: SwapIcon,
    title: "naming.transfers",
    active: true
  },
  [paths.ALERTS]: {
    id: LinkIds.ALERTS,
    to: paths.ALERTS,
    icon: SwapIcon,
    title: "naming.alerts",
    active: process.env.REACT_APP_ALERTS_DISABLED !== "true"
  },
  [paths.TRANSACTIONS]: {
    id: LinkIds.TRANSACTIONS,
    to: paths.TRANSACTIONS,
    icon: SwapIcon,
    title: "naming.transactions",
    active: true
  },
  [paths.RISK_SETTINGS]: {
    id: LinkIds.RISK_SETTINGS,
    to: paths.RISK_SETTINGS,
    icon: HomeIcon,
    title: "amlRiskSettings.title",
    active: true
  },
};


export const NAVIGATION_LINKS: TLinks[] = [
  NAVIGATIONS[paths.HOME],
  NAVIGATIONS[paths.TRANSFERS],
  NAVIGATIONS[paths.ALERTS],
  NAVIGATIONS[paths.RISK_SETTINGS],
  // NAVIGATIONS[paths.TRANSACTIONS],
];