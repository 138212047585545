import { OutputSelector } from "reselect";

import { ECurrency, TNullable } from "../../config/types";

import { IApplicationState, IPaginationResponse, TRequestDataExistsHandler } from "../rootInterface";
import { EClientType, EPreviewType, ERiskLevel } from "../clients/types";


export enum ETransactionsFilterKeys {
  client = "client",
  type = "type",
  currency = "currency",
  scope_of_interaction = "scope_of_interaction",
  date_from = "date_from",
  date_to = "date_to",
  search = "search",
}

export type TTransactionsFilter = {
  [ETransactionsFilterKeys.client]?: string;
  [ETransactionsFilterKeys.type]?: string;
  [ETransactionsFilterKeys.currency]?: ECurrency;
  [ETransactionsFilterKeys.scope_of_interaction]?: string;
  [ETransactionsFilterKeys.date_from]?: TNullable<string>;
  [ETransactionsFilterKeys.date_to]?: TNullable<string>;
  [ETransactionsFilterKeys.search]?: string;
};

export enum ETransactionDirection {
  in = "in",
  out = "out",
  transfer = "transfer",
  exchange = "exchange",
  fiat_in = "fiat_in",
  fiat_out = "fiat_out",
}
export type TTransactionParts = {
  amount: number
  asset: string
  cost: TNullable<number>
  asset_verified: boolean
  asset_icon: TNullable<string>
}
export type TransactionWalletOperation = {
  wallet_name: string
  parts: TTransactionParts[]
}
export type TransactionFiatOperations = {
  client_type: EClientType
  client_name: string
  currency: string
  amount: number
  details: TNullable<string>
}
export type TransactionExtOperation = {
  type: EPreviewType
  asset: string
  amount: number
  blockchain_name: string
  blockchain_address: string
  details: string
}

export type TTransaction = {
  id: string
  direction: ETransactionDirection
  risk_level: ERiskLevel
  type_str: string
  occurred_at: string
  operation_1: TransactionWalletOperation
  operation_2: TNullable<TransactionWalletOperation>
  fiat_operation: TransactionFiatOperations
  ext_operation: TransactionExtOperation
  notes_exist: boolean
  tags_exist: boolean
}
export type TTransactionsData = IPaginationResponse<TTransaction>
export type TTransactionsState = TRequestDataExistsHandler<TTransactionsData> & {
  infiniteScroll?: boolean;
  lastElementFromPreviousPage: TNullable<TTransaction>;
  firstElementOfNextPage: TNullable<TTransaction>;
  initialLoading: boolean
}

export type TTransactionsStoreState = {
  transactions: TTransactionsState
};

export type TTransactionsOutputSelector<T> = OutputSelector<IApplicationState, T, (s: TTransactionsStoreState) => T>;
