import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";

import { TCommonStoreState } from "./types";

const initialState: TCommonStoreState = {
  countriesState: defaultState,
  countriesISOState: defaultState,
  timezonesState: defaultState,
  authCarouseState: {
    selected: 0
  },
  mobileAdditionalMenu: false,
  headerDrawer: false
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearCommonState() {
      return initialState;
    },

    getCountriesRequest(state) {
      state.countriesState = {
        ...state.countriesState,
        fetching: true,
      };
    },
    getCountriesSuccess(state, action) {
      state.countriesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    getCountriesFailure(state, action) {
      state.countriesState = {
        ...state.countriesState,
        fetching: false,
        failure: action.payload,
      };
    },

    getCountriesISORequest(state) {
      state.countriesISOState = {
        ...state.countriesISOState,
        fetching: true,
      };
    },
    getCountriesISOSuccess(state, action) {
      state.countriesISOState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    getCountriesISOFailure(state, action) {
      state.countriesISOState = {
        ...state.countriesISOState,
        fetching: false,
        failure: action.payload,
      };
    },

    getTimezonesRequest(state) {
      state.timezonesState = {
        ...state.timezonesState,
        fetching: true,
      };
    },
    getTimezonesSuccess(state, action) {
      state.timezonesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    getTimezonesFailure(state, action) {
      state.timezonesState = {
        ...state.timezonesState,
        fetching: false,
        failure: action.payload,
      };
    },

    setAuthCarousel(state, action) {
      state.authCarouseState = {
        selected: action.payload
      };
    },

    setMobileAdditionalMenu(state, action) {
      state.mobileAdditionalMenu = action.payload;
    },
    setHeaderDrawer(state, action) {
      state.headerDrawer = action.payload;
    }
  },
});

export const {
  clearCommonState,

  getCountriesRequest,
  getCountriesSuccess,
  getCountriesFailure,

  getCountriesISORequest,
  getCountriesISOSuccess,
  getCountriesISOFailure,


  getTimezonesRequest,
  getTimezonesSuccess,
  getTimezonesFailure,

  setAuthCarousel,
  setMobileAdditionalMenu,
  setHeaderDrawer,
} = commonSlice.actions;

export default commonSlice.reducer;
