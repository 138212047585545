import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import dayjs from "dayjs";

import i18n from "../i18n";
import { GroupedTransactions } from "../store/clients/selectors";

import { setDateTimeLocale } from "./dateHelpers";

const getGroupedTransactions = <T extends Record<string, any>, K extends keyof T>(
  transactions: T[], key: K
): GroupedTransactions<T> => {
  setDateTimeLocale();
  const sort = sortBy(transactions, key);
  return groupBy(sort.reverse(), transaction => dayjs(transaction[key]).locale(i18n.language).format("DD MMM, YYYY"));
};


export default getGroupedTransactions;