import { FC } from "react";
import { Menu as AntMenu } from "antd";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";

import { NAVIGATION_LINKS } from "config/constants";
import { paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { useActiveNavLink } from "../../../Header/hooks";

import SidebarBottom from "../SidebarBottom";

const SidebarBody: FC = () => {
  const { t } = useTranslation();
  const { logo } = useLayoutAppNameContext();
  const isActiveLink = useActiveNavLink();

  return  (
    <PerfectScrollbar
      options={{ suppressScrollX: true }}
    >
      <NavLink to={paths.HOME} exact={true} className="sidebar__logo">
        {logo}
      </NavLink>
      <AntMenu
        mode="inline"
        className="sidebar__nav"
      >
        {NAVIGATION_LINKS.filter(link => link.active).map(link => (
          <li key={link.id} className={cn("sidebar-nav-item", { active: isActiveLink(link.to) })}>
            <NavLink
              isActive={() => isActiveLink(link.to)}
              to={link.to}
              exact={true}
              className="sidebar-nav-item-link"
              id={link.id}
              activeClassName="sidebar-nav-item-link__active"
            >
              {t(link.title)}
            </NavLink>
          </li>
        ))}
      </AntMenu>
      <SidebarBottom/>
    </PerfectScrollbar>
  );
};

export default SidebarBody;
