import { createSelector } from "@reduxjs/toolkit";

import getGroupedTransactions from "../../utils/getGroupedTransactions";

import { IApplicationState } from "../rootInterface";

import { EClientsAccountType, TFiatTransaction } from "./types";

const selectState = (state: IApplicationState) => state.clients;

export const clientsSelector = createSelector(selectState, clients => clients?.clientsState);
export const creatCreateSelector = createSelector(selectState, clients => clients?.creatCreateState);
export const clientDeleteSelector = createSelector(selectState, clients => clients?.clientDeleteState);
export const clientsInfoSelector = createSelector(selectState, clients => clients?.clientsInfoState);
export const clientsAccountsSelector = createSelector(selectState, clients => clients?.clientsAccountsState);
export const clientsAccountsBankAccountSelector =
  createSelector(selectState, clients => 
    clients?.clientsAccountsState.data?.
      filter(item => item.type === EClientsAccountType.BANK_ACCOUNT));
export const clientsAccountsWalletsSelector =
  createSelector(selectState, clients =>
    clients?.clientsAccountsState.data?.
      filter(item => item.type !== EClientsAccountType.BANK_ACCOUNT));
export const clientsTransactionPreviewSelector =
  createSelector(selectState, clients => clients?.clientsTransactionPreviewState);
export const accountsDeleteTransactionsSelector =
  createSelector(selectState, clients => clients?.accountsDeleteTransactionsState);
export const clientsConnectWalletSelector =
  createSelector(selectState, clients => clients?.clientsConnectWalletState);
export const clientsCryptoConnectionsSelector =
  createSelector(selectState, clients => clients?.clientsCryptoConnectionsState);


interface Dictionary<T> {
  [Key: string]: T;
}
export type GroupedTransactions<T> = Dictionary<[...T[]]>

export const clientsTransactionsSelector =
  createSelector(selectState, clients => clients?.clientsFiatTransactionsState);
export const groupedClientsTransactionsSelector =
  createSelector(selectState, clients =>
    getGroupedTransactions<TFiatTransaction, keyof TFiatTransaction>(
      clients?.clientsFiatTransactionsState.data?.results || [], "occurred_at"));

export const clientFiatTransactionDetailsSelector =
  createSelector(selectState, clients => clients?.clientFiatTransactionDetailsState);