import { FC } from "react";

import BNSpinner from "components/BNSpinner";

const LoadingPage: FC = () => (
  <div className="loading-page">
    <BNSpinner/>
  </div>
);

export default LoadingPage;
