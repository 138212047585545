import { createSlice } from "@reduxjs/toolkit";

import { defaultPaginationState, defaultState } from "../constants";
import { IPaginationResponse, IPayloadAction } from "../rootInterface";

import {
  AmlRiskModel,
  AmlRiskModelInfoOptions,
  AmlRisksEntityCategoriesOptions,
  AmlRisksModelActivateOptions,
  AmlRisksModelCreateOptions,
  AmlRisksModelDeleteOptions,
  AmlRisksModelEditOptions,
  AmlRisksModelRulesOptions,
  AmlRisksModelUpdateRulesOptions, TAlert,
  TAlertsOptions,
  TAmlStoreState
} from "./types";

const initialState: TAmlStoreState = {
  amlRiskSummaryState: defaultState,
  amlRisksModelsState: defaultState,
  amlRiskModelInfoState: defaultState,
  amlRisksModelCreateState: defaultState,
  amlRisksModelActivateState: defaultState,
  amlRisksModelDeleteState: defaultState,
  amlRisksEntityCategoriesState: defaultState,
  amlRisksModelEditState: defaultState,
  amlRisksModelRulesState: defaultState,
  amlRisksModelUpdateRulesState: defaultState,
  amlActivateDropdown: false,
  alerts: {
    ...defaultState,
    data: defaultPaginationState
  },
};

const amlSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearAmlState() {
      return initialState;
    },

    amlRiskSummaryRequest(state) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: true,
      };
    },
    amlRiskSummarySuccess(state, action) {
      state.amlRiskSummaryState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRiskSummaryFailure(state, action) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: false,
        failure: action.payload,
      };
    },

    amlRisksModelsRequest(state) {
      state.amlRisksModelsState = {
        ...state.amlRisksModelsState,
        fetching: true,
      };
    },
    amlRisksModelsSuccess(state, action) {
      state.amlRisksModelsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelsFailure(state, action) {
      state.amlRisksModelsState = {
        ...state.amlRisksModelsState,
        fetching: false,
        failure: action.payload,
      };
    },
    amlRisksModelsAppend(state, action: IPayloadAction<AmlRiskModel>) {
      state.amlRisksModelsState.data?.push(action.payload);
    },
    amlRisksModelRemove(state, action: IPayloadAction<{ id: string }>) {
      state.amlRisksModelsState.data =
        state.amlRisksModelsState.data?.filter(item => item.id !== action.payload.id) || [];
    },
    amlRisksModelEdit(state, action: IPayloadAction<AmlRiskModel>) {
      state.amlRisksModelsState.data = state.amlRisksModelsState.data?.map(item => {
        if (item.id !== action.payload.id) return item;
        return action.payload;
      }) || [];
    },
    amlRisksModelSetActivate(state, action: IPayloadAction<{ id: string }>) {
      state.amlRisksModelsState.data = state.amlRisksModelsState.data?.map(item => {
        if (item.id !== action.payload.id) return { ...item, is_active: false };
        return { ...item, is_active: true };
      }) || [];
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRiskModelInfoRequest(state, _: IPayloadAction<AmlRiskModelInfoOptions>) {
      state.amlRiskModelInfoState = {
        ...state.amlRiskModelInfoState,
        fetching: true,
      };
    },
    amlRiskModelInfoSuccess(state, action) {
      state.amlRiskModelInfoState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRiskModelInfoFailure(state, action) {
      state.amlRiskModelInfoState = {
        ...state.amlRiskModelInfoState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelCreateRequest(state, _: IPayloadAction<AmlRisksModelCreateOptions>) {
      state.amlRisksModelCreateState = {
        ...state.amlRisksModelCreateState,
        fetching: true,
      };
    },
    amlRisksModelCreateSuccess(state, action) {
      state.amlRisksModelCreateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelCreateFailure(state, action) {
      state.amlRisksModelCreateState = {
        ...state.amlRisksModelCreateState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelActivateRequest(state, _: IPayloadAction<AmlRisksModelActivateOptions>) {
      state.amlRisksModelActivateState = {
        ...state.amlRisksModelActivateState,
        fetching: true,
      };
    },
    amlRisksModelActivateSuccess(state, action) {
      state.amlRisksModelActivateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelActivateFailure(state, action) {
      state.amlRisksModelActivateState = {
        ...state.amlRisksModelActivateState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelDeleteRequest(state, _: IPayloadAction<AmlRisksModelDeleteOptions>) {
      state.amlRisksModelDeleteState = {
        ...state.amlRisksModelDeleteState,
        fetching: true,
      };
    },
    amlRisksModelDeleteSuccess(state, action) {
      state.amlRisksModelDeleteState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelDeleteFailure(state, action) {
      state.amlRisksModelDeleteState = {
        ...state.amlRisksModelDeleteState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksEntityCategoriesRequest(state, _: IPayloadAction<AmlRisksEntityCategoriesOptions>) {
      state.amlRisksEntityCategoriesState = {
        ...state.amlRisksEntityCategoriesState,
        fetching: true,
      };
    },
    amlRisksEntityCategoriesSuccess(state, action) {
      state.amlRisksEntityCategoriesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksEntityCategoriesFailure(state, action) {
      state.amlRisksEntityCategoriesState = {
        ...state.amlRisksEntityCategoriesState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelEditRequest(state, _: IPayloadAction<AmlRisksModelEditOptions>) {
      state.amlRisksModelEditState = {
        ...state.amlRisksModelEditState,
        fetching: true,
      };
    },
    amlRisksModelEditSuccess(state, action) {
      state.amlRisksModelEditState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelEditFailure(state, action) {
      state.amlRisksModelEditState = {
        ...state.amlRisksModelEditState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelRulesRequest(state, _: IPayloadAction<AmlRisksModelRulesOptions>) {
      state.amlRisksModelRulesState = {
        ...state.amlRisksModelRulesState,
        fetching: true,
      };
    },
    amlRisksModelRulesSuccess(state, action) {
      state.amlRisksModelRulesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelRulesFailure(state, action) {
      state.amlRisksModelRulesState = {
        ...state.amlRisksModelRulesState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelUpdateRulesRequest(state, _: IPayloadAction<AmlRisksModelUpdateRulesOptions>) {
      state.amlRisksModelUpdateRulesState = {
        ...state.amlRisksModelUpdateRulesState,
        fetching: true,
      };
    },
    amlRisksModelUpdateRulesSuccess(state, action) {
      state.amlRisksModelUpdateRulesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelUpdateRulesFailure(state, action) {
      state.amlRisksModelUpdateRulesState = {
        ...state.amlRisksModelUpdateRulesState,
        fetching: false,
        failure: action.payload,
      };
    },

    setAmlActivateDropdown(state, action) {
      state.amlActivateDropdown = action.payload;
    },

    alertsRequest(state, action: IPayloadAction<TAlertsOptions>) {
      state.alerts = {
        ...state.alerts,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    alertsSuccess(state, action: IPayloadAction<{
      data: IPaginationResponse<TAlert>, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.alerts?.data?.results || [];

        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.alerts = {
        fetching: false,
        data: newData,
        failure: null,
      };
    },
    alertsFailure(state, action) {
      state.alerts = {
        ...state.alerts,
        fetching: false,
        failure: action.payload,
      };
    },
  }
});

export const {
  clearAmlState,

  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
  amlRiskSummaryFailure,

  amlRisksModelsRequest,
  amlRisksModelsSuccess,
  amlRisksModelsFailure,
  amlRisksModelsAppend,
  amlRisksModelRemove,
  amlRisksModelEdit,
  amlRisksModelSetActivate,

  amlRiskModelInfoRequest,
  amlRiskModelInfoSuccess,
  amlRiskModelInfoFailure,

  amlRisksModelCreateRequest,
  amlRisksModelCreateSuccess,
  amlRisksModelCreateFailure,

  amlRisksModelActivateRequest,
  amlRisksModelActivateSuccess,
  amlRisksModelActivateFailure,

  amlRisksModelDeleteRequest,
  amlRisksModelDeleteSuccess,
  amlRisksModelDeleteFailure,

  amlRisksEntityCategoriesRequest,
  amlRisksEntityCategoriesSuccess,
  amlRisksEntityCategoriesFailure,

  amlRisksModelEditRequest,
  amlRisksModelEditSuccess,
  amlRisksModelEditFailure,
  
  amlRisksModelRulesRequest,
  amlRisksModelRulesSuccess,
  amlRisksModelRulesFailure,

  amlRisksModelUpdateRulesRequest,
  amlRisksModelUpdateRulesSuccess,
  amlRisksModelUpdateRulesFailure,

  alertsRequest,
  alertsSuccess,
  alertsFailure,

  setAmlActivateDropdown
} = amlSlice.actions;
export default amlSlice.reducer;
