import { combineReducers } from "@reduxjs/toolkit";

import authSlice from "./auth/reducers";
import modalsSlice from "./modals/reducers";
import userSlice from "./user/reducers";
import commonSlice from "./common/reducers";
import clientsSlice from "./clients/reducers";
import transactionsSlice from "./transactions/reducers";
import portfolioSlice from "./portfolio/reducers";
import amlSlice from "./aml/reducers";

export const rootReducer = combineReducers({
  modals: modalsSlice,
  auth: authSlice,
  user: userSlice,
  common: commonSlice,
  clients: clientsSlice,
  transactions: transactionsSlice,
  portfolio: portfolioSlice,
  aml: amlSlice,
});
