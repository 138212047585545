import React, { FC } from "react";

export const SignInPage = React.lazy(() => import("pages/auth/sign-in"));
export const ClientsPage = React.lazy(() => import("pages/clients"));
export const ClientsDetailPage = React.lazy(() => import("pages/clients/detail"));
export const ClientsTransactionsPage = React.lazy(() => import("pages/clients/transactions"));
export const ClientsWalletPage = React.lazy(() => import("pages/clients/wallet"));
export const TransfersPage = React.lazy(() => import("pages/transfers"));
export const TransactionsPage = React.lazy(() => import("pages/transactions"));
export const AlertsPage = React.lazy(() => import("pages/alerts"));
export const AlertsDetailPage = React.lazy(() => import("pages/alerts/details"));
export const RiskSettingsPage = React.lazy(() => import("pages/risk-settings"));
export const RiskSettingsDetailPage = React.lazy(() => import("pages/risk-settings/detail"));

import { paths } from "./paths";

export type TRoutes = {
  path: string;
  component: FC;
  active: boolean;
  exact: boolean;
};

export const privateRoutes: TRoutes[] = [
  { path: paths.HOME, component: ClientsPage, active: true, exact: true, },
  {
    path: paths.ALERTS,
    component: AlertsPage,
    active: process.env.REACT_APP_ALERTS_DISABLED !== "true",
    exact: true,
  },
  { path: paths.ALERTS_DETAIL, component: AlertsDetailPage, active: true, exact: true, },
  { path: paths.CLIENTS_DETAIL, component: ClientsDetailPage, active: true, exact: true, },
  { path: paths.CLIENTS_TRANSACTIONS, component: ClientsTransactionsPage, active: true, exact: true, },
  { path: paths.CLIENTS_WALLET, component: ClientsWalletPage, active: true, exact: true, },
  { path: paths.TRANSFERS, component: TransfersPage, active: true, exact: true, },
  { path: paths.TRANSACTIONS, component: TransactionsPage, active: true, exact: true, },
  { path: paths.RISK_SETTINGS, component: RiskSettingsPage, active: true, exact: true },
  { path: paths.RISK_SETTINGS_DETAILS, component: RiskSettingsDetailPage, active: true, exact: true },
];

export const publicRoutes: TRoutes[] = [
  { path: paths.SIGN_IN, component: SignInPage, active: true, exact: true, },
];

export const allRoutes = privateRoutes.concat(publicRoutes);