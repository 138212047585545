import { AxiosResponse } from "axios";
import { put, takeLatest, all, call } from "redux-saga/effects";

import request from "API";

import { IPayloadAction } from "store/rootInterface";
import {
  TAuthAction,
  TAuthRequest,
} from "store/auth/types";
import { getUserInfoRequest } from "store/user/reducers";
import { analyticEvent } from 'store/analytics/effects';
import { EEventType } from 'store/analytics/types';
import { clearModalsState } from "store/modals/reducers";

import { showSuccess } from "utils/notifications";
import { getRecaptchaToken } from "utils/recaptchaExecute";
import { setStorage, EStorageKeys, removeStorageItem } from "utils/storageHeplers";

import i18n from "../../i18n";

import { INITIAL_REQUESTS, USER_LOGOUT } from "./actions";
import {
  getSignInSuccess,
  getSignInRequest,
  getSignInFailure,
  logout as logoutAction,
  clearAuthState,
} from "./reducers";

function* initRequests() {
  yield put(getUserInfoRequest());
}

function* userLogout() {
  removeStorageItem(EStorageKeys.TOKEN);

  yield put(analyticEvent(EEventType.AUH_SESSION_LOGOUT));
  yield put(logoutAction());
  yield put(clearAuthState());
  yield put(clearModalsState());
}

function* signIn(action: IPayloadAction<TAuthAction>) {
  yield put(analyticEvent(EEventType.LOGIN_SENT));

  try {
    const recaptcha: string = yield call(getRecaptchaToken);
    const response: AxiosResponse<TAuthRequest> = yield call(
      request.post,
      "/auth/email/sign-in/",
      { ...action.payload, recaptcha }
    );
    yield put(getSignInSuccess(response.data));
    setStorage(EStorageKeys.TOKEN, response.data);
    showSuccess(i18n.t("notification.signIn"));
    yield call(initRequests);
  } catch (e) {
    yield put(getSignInFailure(e));
  }
}

function* Saga(): Generator {
  yield all([
    takeLatest(INITIAL_REQUESTS, initRequests),
    takeLatest(USER_LOGOUT, userLogout),
    takeLatest(getSignInRequest.type, signIn),
  ]);
}

export default Saga;
