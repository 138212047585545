import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import cn from "classnames";
import { NavLink } from "react-router-dom";

import { TLinks } from "config/types";

import { TBNDropdownChildren } from "components/BNDropdown/types";
import BNDropdown from "components/BNDropdown";
import { useLayoutAppNameContext } from "components/Layout/hooks";
import ArrowSmallIcon from "components/Icons/ArrowSmallIcon";

import { getUserInfoDataSelector } from "store/user/selectors";

export const SETTINGS_LINKS: TLinks[] = [];
const HeaderAdditional: FC = () => {
  const { t } = useTranslation();
  const data = useSelector(getUserInfoDataSelector);
  const { logout } = useLayoutAppNameContext();

  const overlay = useCallback(({ setActive }: TBNDropdownChildren) => (
    <>
      {
        SETTINGS_LINKS.filter(link => link.active).map(link => (
          <NavLink
            key={link.id}
            to={link.to}
            className={cn("dropdown-item")}
            activeClassName="active"
            exact={true}
            onClick={() => setActive(false)}
          >
            <span>{t(link.title)}</span>
          </NavLink>
        ))
      }
      <div className="dropdown-item exit" onClick={logout}>
        {t('naming.logout')}
      </div>
    </>
  ), []);

  return (
    <BNDropdown overlay={overlay} className="other-navigation new-header__additional-menu">
      {({ active }) => (
        <>
          <div className="other-navigation__text">{data?.username || data?.email || data?.phone}</div>
          <div
            className={cn("other-navigation_icon", {
              active,
            })}
          >
            <ArrowSmallIcon/>
          </div>
        </>
      )}
    </BNDropdown>
  );
};

export default HeaderAdditional;
