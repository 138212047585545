
import { ECurrency } from "../../config/types";

import { EClientType, EPreviewType, ERiskLevel } from "../clients/types";

import { ETransactionDirection, TTransaction } from "./types";

export const TRANSACTIONS: TTransaction[] = [
  {
    id: String(new Date()),
    direction: ETransactionDirection.in,
    risk_level: ERiskLevel.high,
    type_str: "Some type,",
    occurred_at: String(new Date),
    operation_1: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    operation_2: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    fiat_operation: {
      client_type: EClientType.company,
      client_name: "Client Name",
      currency: ECurrency.usd,
      amount: 1234,
      details: "Some details",
    },
    ext_operation: {
      type: EPreviewType.fiat,
      asset: "BNB",
      amount: 100,
      blockchain_name: "Blockchnain",
      blockchain_address: "Some address blockchein",
      details: "not details",
    },
    notes_exist: false,
    tags_exist: true
  },
  {
    id: String(new Date()),
    direction: ETransactionDirection.out,
    risk_level: ERiskLevel.high,
    type_str: "Some type,",
    occurred_at: String(new Date),
    operation_1: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    operation_2: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
      ]
    },
    fiat_operation: {
      client_type: EClientType.company,
      client_name: "Client Name",
      currency: ECurrency.usd,
      amount: 1234,
      details: "Some details",
    },
    ext_operation: {
      type: EPreviewType.fiat,
      asset: "BNB",
      amount: 100,
      blockchain_name: "Blockchnain",
      blockchain_address: "Some address blockchein",
      details: "not details",
    },
    notes_exist: false,
    tags_exist: true
  },
  {
    id: String(new Date()),
    direction: ETransactionDirection.transfer,
    risk_level: ERiskLevel.severe,
    type_str: "Some type,",
    occurred_at: String(new Date),
    operation_1: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    operation_2: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
      ]
    },
    fiat_operation: {
      client_type: EClientType.company,
      client_name: "Client Name",
      currency: ECurrency.usd,
      amount: 1234,
      details: "Some details",
    },
    ext_operation: {
      type: EPreviewType.fiat,
      asset: "BNB",
      amount: 100,
      blockchain_name: "Blockchnain",
      blockchain_address: "Some address blockchein",
      details: "not details",
    },
    notes_exist: false,
    tags_exist: true
  },
  {
    id: String(new Date()),
    direction: ETransactionDirection.exchange,
    risk_level: ERiskLevel.severe,
    type_str: "Some type,",
    occurred_at: String(new Date),
    operation_1: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    operation_2: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
      ]
    },
    fiat_operation: {
      client_type: EClientType.company,
      client_name: "Client Name",
      currency: ECurrency.usd,
      amount: 1234,
      details: "Some details",
    },
    ext_operation: {
      type: EPreviewType.fiat,
      asset: "BNB",
      amount: 100,
      blockchain_name: "Blockchnain",
      blockchain_address: "Some address blockchein",
      details: "not details",
    },
    notes_exist: false,
    tags_exist: true
  },
  {
    id: String(new Date()),
    direction: ETransactionDirection.fiat_in,
    risk_level: ERiskLevel.severe,
    type_str: "Some type,",
    occurred_at: String(new Date),
    operation_1: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    operation_2: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
      ]
    },
    fiat_operation: {
      client_type: EClientType.company,
      client_name: "Client Name",
      currency: ECurrency.usd,
      amount: 1234,
      details: "Some details",
    },
    ext_operation: {
      type: EPreviewType.fiat,
      asset: "BNB",
      amount: 100,
      blockchain_name: "Blockchnain",
      blockchain_address: "Some address blockchein",
      details: "not details",
    },
    notes_exist: false,
    tags_exist: true
  },
  {
    id: String(new Date()),
    direction: ETransactionDirection.fiat_out,
    risk_level: ERiskLevel.severe,
    type_str: "Some type,",
    occurred_at: String(new Date),
    operation_1: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        }
      ]
    },
    operation_2: {
      wallet_name: "Some wallet",
      parts: [
        {
          amount: 100,
          asset: "TEST",
          cost: 12345,
          asset_verified: true,
          asset_icon: null,
        },
      ]
    },
    fiat_operation: {
      client_type: EClientType.company,
      client_name: "Client Name",
      currency: ECurrency.usd,
      amount: 1234,
      details: "Some details",
    },
    ext_operation: {
      type: EPreviewType.fiat,
      asset: "BNB",
      amount: 100,
      blockchain_name: "Blockchnain",
      blockchain_address: "Some address blockchein",
      details: "not details",
    },
    notes_exist: false,
    tags_exist: true
  },
];