import { createSlice } from "@reduxjs/toolkit";

import { defaultInitialLoadingState, defaultPaginationState, defaultState, PAGINATION_PAGE_LIMIT } from "../constants";
import { IPaginationResponse, IPayloadAction, TPaginationOptions } from "../rootInterface";

import {
  TAccountsUploadTransactionsOptions,
  TClient,
  TClientCreateOptions,
  TClientFiatTransactionDetails,
  TClientFiatTransactionDetailsOptions,
  TClientsAccount,
  TClientsAccountsOptions,
  TClientsConnectWallet,
  TClientsConnectWalletOptions,
  TClientsCryptoConnectionsData,
  TClientsCryptoConnectionsOptions,
  TClientsInfo,
  TClientsInfoOptions,
  TClientsOptions,
  TClientsStoreState,
  TClientsTransactionPreviewData,
  TClientsTransactionPreviewOptions,
  TClientsFiatTransactionsData,
  TClientsFiatTransactionsOptions,
  TClintDeleteOptions,
  TAccountsDeleteTransactionsOptions,
} from "./types";

const initialState: TClientsStoreState = {
  clientsState: defaultState,
  creatCreateState: defaultState,
  clientDeleteState: defaultState,
  clientsInfoState: defaultInitialLoadingState,
  clientsAccountsState: defaultInitialLoadingState,
  clientsTransactionPreviewState: defaultState,
  accountsUploadTransactionsState: defaultState,
  accountsDeleteTransactionsState: defaultState,
  clientsConnectWalletState: defaultInitialLoadingState,
  clientsCryptoConnectionsState: defaultInitialLoadingState,
  clientsFiatTransactionsState: {
    ...defaultState,
    data: defaultPaginationState,
    lastElementFromPreviousPage: null,
    firstElementOfNextPage: null,
    initialLoading: true,
  },
  clientFiatTransactionDetailsState: defaultState,
};

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    clientsRequest(state, action: IPayloadAction<TClientsOptions>) {
      state.clientsState = {
        ...state.clientsState,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    clientsSuccess(state, action: IPayloadAction<{
      data: IPaginationResponse<TClient>, infiniteScroll?: boolean;
    }>) {
      let newData = action.payload.data;
      if (action.payload.infiniteScroll) {
        const prevResults = state?.clientsState?.data?.results || [];
        newData = {
          ...action.payload.data,
          results: [...prevResults, ...(action.payload?.data?.results || [])]
        };
      }
      state.clientsState = {
        fetching: false,
        data: newData,
        failure: null,
      };
    },
    clientAppend(state, action: IPayloadAction<TClient>){
      const prev = state.clientsState.data as IPaginationResponse<TClient>;
      state.clientsState.data = {
        ...prev,
        results: [action.payload, ...prev.results]
      };
    },
    clientsFailure(state, action) {
      state.clientsState = {
        ...state.clientsState,
        fetching: false,
        failure: action.payload,
      };
    },

    creatCreateRequest(state, _: IPayloadAction<TClientCreateOptions>) {
      state.creatCreateState = {
        ...state.creatCreateState,
        fetching: true,
      };
    },
    creatCreateSuccess(state, action: IPayloadAction<TClient>) {
      state.creatCreateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    creatCreateFailure(state, action) {
      state.creatCreateState = {
        ...state.creatCreateState,
        fetching: false,
        failure: action.payload,
      };
    },


    clientDeleteRequest(state, _: IPayloadAction<TClintDeleteOptions>) {
      state.clientDeleteState = {
        ...state.clientDeleteState,
        fetching: true,
      };
    },
    clientDeleteSuccess(state, action) {
      state.clientDeleteState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    clientDeleteFailure(state, action) {
      state.clientDeleteState = {
        ...state.clientDeleteState,
        fetching: false,
        failure: action.payload,
      };
    },

    clientsInfoRequest(state, _: IPayloadAction<TClientsInfoOptions>) {
      state.clientsInfoState = {
        ...state.clientsInfoState,
        fetching: true,
      };
    },
    clientsInfoSuccess(state, action: IPayloadAction<TClientsInfo>) {
      state.clientsInfoState = {
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    clientsInfoFailure(state, action) {
      state.clientsInfoState = {
        ...state.clientsInfoState,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    clientsInfoClear(state) {
      state.clientsInfoState = defaultInitialLoadingState;
    },

    clientsAccountsRequest(state, _: IPayloadAction<TClientsAccountsOptions>) {
      state.clientsAccountsState = {
        ...state.clientsAccountsState,
        fetching: true,
      };
    },
    clientsAccountsSuccess(state, action: IPayloadAction<TClientsAccount[]>) {
      state.clientsAccountsState = {
        initialLoading: false,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    clientsAccountsFailure(state, action) {
      state.clientsAccountsState = {
        ...state.clientsAccountsState,
        initialLoading: false,
        fetching: false,
        failure: action.payload,
      };
    },
    clientsAccountsClear(state) {
      state.clientsAccountsState = defaultInitialLoadingState;
    },

    clientsTransactionPreviewRequest(state, _: IPayloadAction<TClientsTransactionPreviewOptions>) {
      state.clientsTransactionPreviewState = {
        ...state.clientsTransactionPreviewState,
        fetching: true,
      };
    },
    clientsTransactionPreviewSuccess(state, action: IPayloadAction<TClientsTransactionPreviewData>) {
      state.clientsTransactionPreviewState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    clientsTransactionPreviewFailure(state, action) {
      state.clientsTransactionPreviewState = {
        ...state.clientsTransactionPreviewState,
        fetching: false,
        failure: action.payload,
      };
    },

    accountsUploadTransactionsRequest(state, _: IPayloadAction<TAccountsUploadTransactionsOptions>) {
      state.accountsUploadTransactionsState = {
        ...state.accountsUploadTransactionsState,
        fetching: true,
      };
    },
    accountsUploadTransactionsSuccess(state, action) {
      state.accountsUploadTransactionsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    accountsUploadTransactionsFailure(state, action) {
      state.accountsUploadTransactionsState = {
        ...state.accountsUploadTransactionsState,
        fetching: false,
        failure: action.payload,
      };
    },

    accountsDeleteTransactionsRequest(state, _: IPayloadAction<TAccountsDeleteTransactionsOptions>) {
      state.accountsDeleteTransactionsState = {
        ...state.accountsDeleteTransactionsState,
        fetching: true,
      };
    },
    accountsDeleteTransactionsSuccess(state, action) {
      state.accountsDeleteTransactionsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    accountsDeleteTransactionsFailure(state, action) {
      state.accountsDeleteTransactionsState = {
        ...state.accountsDeleteTransactionsState,
        fetching: false,
        failure: action.payload,
      };
    },

    clientsConnectWalletRequest(state, _: IPayloadAction<TClientsConnectWalletOptions>) {
      state.clientsConnectWalletState = {
        ...state.clientsConnectWalletState,
        fetching: true,
      };
    },
    clientsConnectWalletSuccess(state, action: IPayloadAction<TClientsConnectWallet[]>) {
      state.clientsConnectWalletState = {
        initialLoading: false,
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    clientsConnectWalletFailure(state, action) {
      state.clientsConnectWalletState = {
        ...state.clientsConnectWalletState,
        fetching: false,
        failure: action.payload,
        initialLoading: false,
      };
    },
    clientsConnectWalletClear(state) {
      state.clientsConnectWalletState = defaultInitialLoadingState;
    },

    clientsCryptoConnectionsRequest(state, _: IPayloadAction<TClientsCryptoConnectionsOptions>) {
      state.clientsCryptoConnectionsState = {
        ...state.clientsCryptoConnectionsState,
        fetching: true,
      };
    },
    clientsCryptoConnectionsSuccess(state, action: IPayloadAction<TClientsCryptoConnectionsData>) {
      state.clientsCryptoConnectionsState = {
        ...state.clientsCryptoConnectionsState,
        fetching: false,
        data: action.payload,
        failure: null,
        initialLoading: false,
      };
    },
    clientsCryptoConnectionsFailure(state, action) {
      state.clientsCryptoConnectionsState = {
        ...state.clientsCryptoConnectionsState,
        fetching: false,
        failure: action.payload,
        initialLoading: false
      };
    },
    clientsCryptoConnectionsClear(state) {
      state.clientsCryptoConnectionsState = defaultInitialLoadingState;
    },

    clientsFiatTransactionsRequest(state, action: IPayloadAction<TClientsFiatTransactionsOptions>) {
      state.clientsFiatTransactionsState = {
        ...state.clientsFiatTransactionsState,
        fetching: true,
        infiniteScroll: action.payload?.infiniteScroll
      };
    },
    clientsFiatTransactionsSuccess(
      state,
      action: IPayloadAction<{
        data: TClientsFiatTransactionsData,
        infiniteScroll?: boolean,
        pagination?: TPaginationOptions
      }>
    ) {
      const { data, infiniteScroll, pagination } = action.payload;
      let newResults = data.results;
      const offset = pagination?.offset || 0;

      state.clientsFiatTransactionsState.fetching = false;
      state.clientsFiatTransactionsState.initialLoading = false;

      switch (pagination?.limit) {
      case PAGINATION_PAGE_LIMIT+2:
        newResults = data.results.slice(1, PAGINATION_PAGE_LIMIT+1);
        state.clientsFiatTransactionsState.lastElementFromPreviousPage = data.results[0];
        state.clientsFiatTransactionsState.data.next =
          data.count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.clientsFiatTransactionsState.firstElementOfNextPage = data.results.length === PAGINATION_PAGE_LIMIT+2
          ? data.results[data.results.length-1]
          : null;
        break;
      case PAGINATION_PAGE_LIMIT+1:
        newResults = data.results.slice(0, PAGINATION_PAGE_LIMIT);
        state.clientsFiatTransactionsState.lastElementFromPreviousPage = null;
        state.clientsFiatTransactionsState.data.next =
          data.count > offset + PAGINATION_PAGE_LIMIT ? offset + PAGINATION_PAGE_LIMIT : null;

        state.clientsFiatTransactionsState.firstElementOfNextPage = data.results.length === PAGINATION_PAGE_LIMIT+1
          ? data.results[data.results.length-1]
          : null;
        break;
      default:
        state.clientsFiatTransactionsState.lastElementFromPreviousPage = null;
        state.clientsFiatTransactionsState.firstElementOfNextPage = null;
        state.clientsFiatTransactionsState.data.next = data.next;
        break;
      }

      state.clientsFiatTransactionsState.data.results = infiniteScroll
        ? [...state.clientsFiatTransactionsState.data.results, ...newResults]
        : newResults;
      state.clientsFiatTransactionsState.data.count = data.count;
      state.clientsFiatTransactionsState.infiniteScroll = infiniteScroll;
    },
    clientsFiatTransactionsFailure(state, action) {
      state.clientsFiatTransactionsState = {
        ...state.clientsFiatTransactionsState,
        initialLoading: false,
        fetching: false,
        failure: action.payload,
      };
    },
    clientsFiatTransactionsClear(state) {
      state.clientsFiatTransactionsState = {
        ...defaultState,
        data: defaultPaginationState,
        lastElementFromPreviousPage: null,
        firstElementOfNextPage: null,
        initialLoading: true,
      };
    },

    clientFiatTransactionDetailsRequest(state, _: IPayloadAction<TClientFiatTransactionDetailsOptions>) {
      state.clientFiatTransactionDetailsState = {
        ...state.clientFiatTransactionDetailsState,
        fetching: true,
      };
    },
    clientFiatTransactionDetailsSuccess(state, action: IPayloadAction<TClientFiatTransactionDetails>) {
      state.clientFiatTransactionDetailsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    clientFiatTransactionDetailsFailure(state, action) {
      state.clientFiatTransactionDetailsState = {
        ...state.clientFiatTransactionDetailsState,
        fetching: false,
        failure: action.payload,
      };
    },
  },
});

export const {
  clientsRequest,
  clientsSuccess,
  clientsFailure,
  clientAppend,

  creatCreateRequest,
  creatCreateSuccess,
  creatCreateFailure,

  clientDeleteRequest,
  clientDeleteSuccess,
  clientDeleteFailure,

  clientsInfoRequest,
  clientsInfoSuccess,
  clientsInfoFailure,
  clientsInfoClear,

  clientsAccountsRequest,
  clientsAccountsSuccess,
  clientsAccountsFailure,
  clientsAccountsClear,

  clientsTransactionPreviewRequest,
  clientsTransactionPreviewSuccess,
  clientsTransactionPreviewFailure,

  accountsUploadTransactionsRequest,
  accountsUploadTransactionsSuccess,
  accountsUploadTransactionsFailure,

  accountsDeleteTransactionsRequest,
  accountsDeleteTransactionsSuccess,
  accountsDeleteTransactionsFailure,

  clientsConnectWalletRequest,
  clientsConnectWalletSuccess,
  clientsConnectWalletFailure,
  clientsConnectWalletClear,

  clientsCryptoConnectionsRequest,
  clientsCryptoConnectionsSuccess,
  clientsCryptoConnectionsFailure,
  clientsCryptoConnectionsClear,

  clientsFiatTransactionsRequest,
  clientsFiatTransactionsSuccess,
  clientsFiatTransactionsFailure,
  clientsFiatTransactionsClear,

  clientFiatTransactionDetailsRequest,
  clientFiatTransactionDetailsSuccess,
  clientFiatTransactionDetailsFailure,
} = clientsSlice.actions;

export default clientsSlice.reducer;
