import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { getClientId, getClientsDetailsPath, paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { clientsInfoSelector } from "../../../../../store/clients/selectors";

import HeaderBack from "../HeaderBack";

const HeaderLeft: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { routePath } = useLayoutAppNameContext();
  const { data: clientInfo } = useSelector(clientsInfoSelector);

  const vectorBack: Record<string, { title: string, call: () => void }> = useMemo(() => ({
    [paths.CLIENTS_DETAIL]: {
      title: t("naming.clients"),
      call: () => {
        history.push(paths.HOME);
      },
    },
    [paths.CLIENTS_TRANSACTIONS]: {
      title: clientInfo?.full_name || "",
      call: () => {
        const clientId = getClientId();
        if (!clientId) return;
        history.push(getClientsDetailsPath(clientId));
      },
    },
    [paths.CLIENTS_WALLET]: {
      title: clientInfo?.full_name || "",
      call: () => {
        const clientId = getClientId();
        if (!clientId) return;
        history.push(getClientsDetailsPath(clientId));
      },
    }
  }), [i18n.language, clientInfo]);

  return (
    <div className="new-header__left">
      {routePath && vectorBack[routePath]
        && <HeaderBack goBack={vectorBack[routePath].call} title={vectorBack[routePath].title} />}
    </div>
  );
};

export default HeaderLeft;