import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.user;


export const getUserInfoSelector = createSelector(
  selectState,
  user => user.userInfo);
export const getUserInfoFetchingSelector = createSelector(
  selectState,
  user => user.userInfo.fetching
);
export const getUserInfoDataSelector = createSelector(
  selectState,
  user => user.userInfo.data
);
export const getUserInfoFailureSelector = createSelector(
  selectState,
  user => user.userInfo.failure
);
