export const PAGINATION_PAGE_LIMIT = 20;

export const defaultState = {
  fetching: false,
  data: null,
  failure: null,
};

export const defaultInitialLoadingState = {
  fetching: false,
  data: null,
  failure: null,
  initialLoading: true
};

export const defaultPaginationState = {
  next: null,
  previous: null,
  count: 0,
  pages	: null,
  results: []
};