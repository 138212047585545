import { ECurrency, TNullable } from "../../config/types";

import {
  IPaginationResponse, TPagination,
  TPaginationOptions, TRequestDataExistsHandler,
  TRequestHandler
} from "../rootInterface";

export enum EClientCryptoStatus {
  no_relations = "no_relations",
  suspicion = "suspicion",
  relations_found = "relations_found",
  wallets_connected  = "wallets_connected",
}
export enum EClientType {
  individual = "individual",
  company = "company",
}

export type TClient = {
  id: string
  created_at: string
  last_activity: TNullable<string>
  crypto_status: TNullable<EClientCryptoStatus>
  alerts_open: TNullable<string>
  alerts_in_progress: TNullable<string>
  ext_id: string
  type: EClientType
  full_name: string
}

export type TClientsFilter = {
  type?: EClientType,
  crypto_relation_status?: EClientCryptoStatus
  id_search?: string
}
export type TClientsOptions = {
  filter?: TClientsFilter
  pagination?: TPaginationOptions
  infiniteScroll?: boolean;
  callOnSuccess?: () => void
} | undefined
export type TClientsState = TRequestHandler<IPaginationResponse<TClient>> & {
  infiniteScroll?: boolean;
}

export type TClientCreateData = {
  type: EClientType
  ext_id: string
  full_name: string
}
export type TClientCreateOptions = {
  data: TClientCreateData
  callOnSuccess?: (id: string) => void
}
export type TClientCreateState = TRequestHandler<TClient>

export type TClintDeleteOptions = {
  id: string
  callOnSuccess?: () => void
}
export type TClientDeleteState = TRequestHandler<any>

export type TClientsInfoOptions = {
  id: string
  fetchTransactionPreviews?: () => void
}
export enum EClientsInfoAnalysisStatus {
  in_progress = "in_progress",
  failed = "failed",
  analyzed = "analyzed",
}
export type TClientsInfo = {
  id: string
  created_at: string
  ext_id: string
  type: EClientType
  full_name: string
  last_activity: TNullable<string>
  crypto_status: EClientCryptoStatus
  alerts_open: number
  alerts_in_progress: number
  analysis_status: TNullable<EClientsInfoAnalysisStatus>
  analyzed_at: TNullable<string>
}
export type TClientsInfoState = TRequestHandler<TClientsInfo> & {
  initialLoading: boolean
}

export enum EClientsAccountType {
  EXCHANGE = "EXCHANGE",
  BLOCKCHAIN = "BLOCKCHAIN",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  WALLET = "WALLET",
}
export enum EClientsAccountStatus {
  synchronizing = "synchronizing",
  synchronized = "synchronized",
  error = "error",
}
export type TClientsAccount = {
  id: string
  name: string
  type: EClientsAccountType
  icon: string
  address: TNullable<string>
  currency: TNullable<ECurrency>
  status: TNullable<EClientsAccountStatus>
  transactions: number
  total_balance: number
  cost_currency: ECurrency
}
export type TClientsAccountsOptions = {
  id: string
}
export type TClientsAccountsState = TRequestHandler<TClientsAccount[]> & {
  initialLoading: boolean
}

export enum EClientsTransactionPreviewAction {
  in = "in",
  out = "out",
  exchange = "exchange",
  transfer = "transfer",
  position_open = "position_open",
  position_closed = "position_closed",
}
export enum EPreviewType {
  fiat = "fiat",
  crypto = "crypto",
}
export type TClientsTransactionPreview = {
  id: string
  action: EClientsTransactionPreviewAction
  subject: string
  occurred_at: string
  amount: number
  asset: string
  extra_amount: string
  extra_asset: string
}
export type TClientsTransactionPreviewOptions = {
  id: string
  params: {
    type: EPreviewType
  }
  callOnSuccess?: (val: IPaginationResponse<TClientsTransactionPreview>) => void
}
export type TClientsTransactionPreviewData = IPaginationResponse<TClientsTransactionPreview>
export type TClientsTransactionPreviewState = TRequestHandler<TClientsTransactionPreviewData>

export type TAccountsUploadTransactionsOptions = {
  id: string
  data: {
    csv_file: File
  }
  callOnSuccess?: () => void
  fetchTransactionPreviews?: () => void
}
export type TAccountsUploadTransactionsState = TRequestHandler<any>

export type TAccountsDeleteTransactionsOptions = {
  id: string
  callOnSuccess?: () => void
}
export type TAccountsDeleteTransactionsState = TRequestHandler<any>

export type TClientsConnectWalletOptions = {
  id: string
}
export type TClientsConnectWallet = {
  id: string
  name: string
  icon: string
  address: TNullable<string>
  connections: number
  total_balance: number
  cost_currency: ECurrency
}
export type TClientsConnectWalletState = TRequestHandler<TClientsConnectWallet[]> & {
  initialLoading: boolean
}

export type TClientsCryptoConnectionsOptions = {
  id: string
  page?: number
  size?: number
}
export type TClientsCryptoConnectionsData = IPaginationResponse<TClientsTransactionPreview>
export type TClientsCryptoConnectionsState = TRequestHandler<TClientsCryptoConnectionsData> & {
  initialLoading: boolean
}

export enum EDirection {
  in = "in",
  out = "out",
}
export enum EClientsTransactionAction {
  c2c_transfer = "с2с_transfer",
  sbp_transfer = "sbp_transfer",
  card_charge = "card_charge",
  card_refund = "card_refund",
  salary_payment = "salary_payment",
  wire_transfer = "wire_transfer",
}
export enum ERiskLevel {
  no_risk = "no_risk",
  low = "low",
  medium = "medium",
  high = "high",
  severe = "severe",
}
export type TFiatTransaction = {
  id: string
  direction: EDirection
  type_str: string
  occurred_at: string
  client_full_name: string
  client_ext_id: string
  fiat_action: EClientsTransactionAction
  fiat_currency: string
  fiat_amount: number
  fiat_details: string
  crypto_wallet: TNullable<string>
  crypto_asset: TNullable<string>
  crypto_amount: TNullable<number>
  crypto_details: TNullable<string>
  risk_level: TNullable<ERiskLevel>
}
export type TClientsFiatTransactionsFilter = {
  client?: string
  risk_level?: ERiskLevel
  type?: string
  date_from?: TNullable<string>
  date_to?: TNullable<string>
  crypto_related_only?: boolean
  search?: string
}
export type TClientsFiatTransactionsOptions = TPagination & {
  filter?: TClientsFiatTransactionsFilter
  infiniteScroll?: boolean;
  callOnSuccess?: (next?: TNullable<number>) => void
}
export type TClientsFiatTransactionsData = IPaginationResponse<TFiatTransaction>
export type TClientsFiatTransactionsState = TRequestDataExistsHandler<TClientsFiatTransactionsData> & {
  infiniteScroll?: boolean;
  lastElementFromPreviousPage: TNullable<TFiatTransaction>;
  firstElementOfNextPage: TNullable<TFiatTransaction>;
  initialLoading: boolean
}

export type TClientFiatTransactionDetailsOptions = {
  id: string
}
export type TCategoryShare = {
  name: string
  share: number
}
export type TExposure = {
  low_risk_share: number
  low_risk_categories: TCategoryShare[]
  medium_risk_share: number
  medium_risk_categories: TCategoryShare[]
  high_risk_share: number
  high_risk_categories: TCategoryShare[]
  severe_risk_value: number
  severe_risk_categories: TCategoryShare[]
}
export type TClientFiatTransactionDetails = TFiatTransaction & {
  fiat_tx_id: string
  fiat_action_str: string
  exposure: TNullable<TExposure>
}
export type TClientFiatTransactionDetailsState = TRequestHandler<TClientFiatTransactionDetails>

export type TClientsStoreState = {
  clientsState: TClientsState
  creatCreateState: TClientCreateState
  clientDeleteState: TClientDeleteState
  clientsInfoState: TClientsInfoState
  clientsAccountsState: TClientsAccountsState
  clientsTransactionPreviewState: TClientsTransactionPreviewState
  accountsUploadTransactionsState: TAccountsUploadTransactionsState
  accountsDeleteTransactionsState: TAccountsDeleteTransactionsState
  clientsConnectWalletState: TClientsConnectWalletState
  clientsCryptoConnectionsState: TClientsCryptoConnectionsState
  clientsFiatTransactionsState: TClientsFiatTransactionsState
  clientFiatTransactionDetailsState: TClientFiatTransactionDetailsState
};
